export default {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,

  useEmulators: process.env.VUE_APP_USE_EMULATORS === "true" ? true : false,

  auditServerBaseUrl: process.env.VUE_APP_AUDIT_SERVER_BASE_URL || "",

  auditStreamerBaseUrl: process.env.VUE_APP_AUDIT_STREAMER_BASE_URL || "",

  fscBaseUrl: process.env.VUE_APP_FSC_BASE_URL || "",

  syncServerBaseUrl: process.env.VUE_APP_SYNC_SERVER_BASE_URL || "",

  textbookBaseUrl: process.env.VUE_APP_TEXTBOOK_BASE_URL || "",

  videoChatServerBaseUrl: process.env.VUE_APP_VIDEO_CHAT_SERVER_BASE_URL || "",
};
