<template>
  <div
    id="nav"
    class="px-4 h-11 bg-blue-500 text-white flex justify-between items-center flex-shrink-0 z-10"
  >
    <router-link
      class="text-xl flex flex-row items-center"
      :to="{ name: 'Courses' }"
    >
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="
          fill-rule: evenodd;
          clip-rule: evenodd;
          stroke-linejoin: round;
          stroke-miterlimit: 2;
          fill: white;
          margin-right: 5px;
        "
      >
        <path
          d="M40.605,61.856L40.998,33.536L66.81,25.905L83.168,49.148L66.82,72.11L40.605,61.856Z"
        />
        <g transform="matrix(1,0,0,1,-27.1727,-1.53567)">
          <path
            d="M45.733,73.084L35.477,50.891L47.218,27.276L64.182,34.87L63.776,63.536L45.733,73.084Z"
          />
        </g>
        <g transform="matrix(1,0,0,1,-12.9917,-28.2309)">
          <path
            d="M52.158,58.149L35.684,50.771L49.589,37.877L77.794,39.121L78.511,50.347L52.158,58.149Z"
          />
        </g>
        <g transform="matrix(1,0,0,1,-11.3006,27.878)">
          <path
            d="M46.833,62.502L32.186,46.966L50.013,37.53L76.643,47.954L74.737,62.219L46.833,62.502Z"
          />
        </g>
        <g transform="matrix(1,0,0,1,26.4934,22.8785)">
          <path
            d="M41.363,64.341L42.945,52.429L59.951,28.557L64.604,29.762L55.081,52.757L41.363,64.341Z"
          />
        </g>
        <g transform="matrix(1,0,0,1,32.0128,-28.7308)">
          <path
            d="M54.815,76.135L37.562,51.616L36.987,42.595L50.196,54.936L58.583,77.111L54.815,76.135Z"
          />
        </g>
      </svg>
      STEAMplug
    </router-link>

    <div class="flex flex-row space-x-6">
      <button @click="toggleHelpMenu()">
        <icon
          :name="'question-mark-circle'"
          :color="'rgb(255, 255, 255)'"
          :width="'20'"
          :height="'20'"
        />
      </button>

      <router-link :to="{ name: 'SignIn' }" v-if="!userSignedIn">
        Sign In
      </router-link>

      <button
        class="h-8 w-8 bg-blue-300 rounded-full flex justify-center items-center cursor-pointer text-xs"
        v-if="userSignedIn"
        @click="
          $event.target.focus();
          toggleUserMenu();
        "
        @blur="userMenu = false"
        @mouseup.prevent
      >
        {{ getUserInitials }}
      </button>
    </div>

    <div
      class="absolute bg-white border border-gray-300 top-11 right-4 w-52 text-black flex flex-col z-10"
      v-if="userMenu && userSignedIn"
    >
      <div class="flex flex-row py-2 px-2 text-xs font-bold">
        <icon :name="'person'" />
        <span class="ml-2">{{ user.displayName }}</span>
      </div>
      <a
        @mousedown="signOut"
        v-if="userSignedIn"
        class="flex flex-row py-2 px-2 cursor-pointer text-xs border-t border-gray-300 hover:bg-gray-100"
      >
        <icon :name="'box-arrow-right'" />
        <span class="ml-2"> Sign out </span>
      </a>
    </div>

    <div
      class="absolute bg-white border border-gray-300 top-11 right-18 w-36 text-black flex flex-col z-10"
      v-if="helpMenu"
    >
      <ul>
        <li
          class="flex flex-row cursor-pointer text-xs border-t border-gray-300 hover:bg-gray-100"
          @click="helpMenu = false"
        >
          <router-link class="flex-grow" :to="{ name: 'Terms' }">
            <div class="flex flex-row px-2 py-2 space-x-2">
              <icon :name="'file-earmak-text'" />
              <span>Terms of Use</span>
            </div>
          </router-link>
        </li>

        <li
          class="flex flex-row cursor-pointer text-xs border-t border-gray-300 hover:bg-gray-100"
          @click="helpMenu = false"
        >
          <router-link class="flex-grow" :to="{ name: 'Privacy' }">
            <div class="flex flex-row px-2 py-2 space-x-2">
              <icon :name="'shield'" />
              <span>Privacy Policy</span>
            </div>
          </router-link>
        </li>

        <li
          class="flex flex-row cursor-pointer text-xs border-t border-gray-300 hover:bg-gray-100"
          @click="helpMenu = false"
        >
          <div class="flex flex-row px-2 py-2 space-x-2">
            <icon :name="'tools'" />
            <a href="mailto:STEAMplug Support<support@steamplug.com>">
              Contact Support
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <idle-sign-out v-if="userSignedIn && !amFollowSessionFollower" />

  <breadcrumb-bar />
  <router-view />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import useAuth from "@/composables/useAuth";

import Icon from "@/components/Icon.vue";
import BreadcrumbBar from "@/components/BreadcrumbBar.vue";
import IdleSignOut from "@/components/IdleSignOut.vue";
import useFollowMode from "./composables/useFollowMode";

export default defineComponent({
  components: { IdleSignOut, Icon, BreadcrumbBar },

  setup() {
    const { user, userSignedIn } = useAuth();
    const router = useRouter();
    const { amFollowSessionFollower } = useFollowMode();

    let userMenu = ref(false);
    const helpMenu = ref(false);
    let userInitials = ref("");

    const getUserInitials = computed(() => {
      if (userSignedIn.value && user.value?.displayName != null) {
        let names = user.value.displayName.split(" ");
        let initials = "";
        names.forEach((name: string) => {
          initials = initials + name[0];
        });

        return initials.substring(0, 3);
      } else {
        return "";
      }
    });

    function toggleUserMenu() {
      if (userMenu.value) {
        userMenu.value = false;
      } else {
        helpMenu.value = false;
        userMenu.value = true;
      }
    }

    function toggleHelpMenu() {
      if (helpMenu.value) {
        helpMenu.value = false;
      } else {
        userMenu.value = false;
        helpMenu.value = true;
      }
    }

    function signOut() {
      userMenu.value = false;
      helpMenu.value = false;
      router.push({ name: "SignOut" });
    }

    function isCodeListing(domElt: any) {
      var curElt = domElt;
      while (curElt) {
        if (
          curElt.className &&
          (curElt.className.indexOf("code-listing-line") !== -1 ||
            curElt.className.indexOf("code-listing") !== -1)
        ) {
          return true;
        }
        curElt = curElt.parentNode;
      }
      return false;
    }

    function copyHandler(e: any) {
      // get current selection
      let selection = window.getSelection();

      // override behavior when one wants to copy lines of code
      if (isCodeListing(selection?.anchorNode)) {
        let selectionText = "";
        // workaround an issue with Microsoft Edge as copied line breaks
        // are removed otherwise from the selection string

        selectionText = selection?.toString() ?? "";

        let lines = selectionText?.split("\n");

        let temp = [];
        for (let i in lines) {
          const pos = lines[i].search("​");
          if (pos >= 0 || lines[i] != "") {
            lines[i] = lines[i].substring(pos + 1, lines[i].length);
            temp.push(lines[i]);
          }
        }
        const cleanText = temp.join("\n");
        e.clipboardData?.setData("text/plain", cleanText);
        e.preventDefault();
      }
    }
    document.addEventListener("copy", copyHandler);

    return {
      userSignedIn,
      userMenu,
      userInitials,
      getUserInitials,
      toggleUserMenu,
      signOut,
      user,
      amFollowSessionFollower,
      helpMenu,
      toggleHelpMenu,
    };
  },
});
</script>

<style>
.styled-scrollbar {
  scrollbar-width: thin;
}

.styled-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: transparent;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.75);
}

.styled-scrollbar::-webkit-scrollbar-corner {
  background: white;
}
</style>
