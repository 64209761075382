import { createApp } from "vue";
import VueResizeObserver from "vue-resize-observer";

import App from "./App.vue";
import { auth } from "@/firebaseManager";
import router from "./router";
import "./assets/tailwind.css";
import useAuth from "./composables/useAuth";

let app: any = null;
const { user, userSignedIn, returnToAfterSignIn } = useAuth();

auth.onAuthStateChanged((firebaseUser) => {
  console.log("AUTH STATE CHANGED");

  if (firebaseUser) {
    user.value = firebaseUser;
    userSignedIn.value = true;

    router.push(returnToAfterSignIn.value);
  } else {
    user.value = undefined;
    userSignedIn.value = false;

    router.push({ name: "SignOut" });
  }

  if (!app) {
    app = createApp(App).use(router).use(VueResizeObserver).mount("#app");
  }
});
