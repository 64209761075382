<template>
  <div
    class="absolute top-18 left-0 right-0 bottom-0 bg-gray-100 flex flex-col items-center overflow-y-scroll"
    style="display: flex"
  >
    <div class="bg-white shadow-md py-12 px-4 my-8 w-screen md:w-2/3 md:px-20">
      <h1 class="text-2xl font-semibold">
        Terms of Service and End User License Agreement
      </h1>
      <p class="mt-1 italic">Last updated: 17 August 2021</p>

      <div class="text-base text-gray-700 space-y-6 text-justify">
        <b
          >PLEASE READ THIS USER AGREEMENT AND THE PRIVACY POLICIY REFERENCED
          HEREIN (COLLECTIVELY DEFINED BELOW AS THE “TERMS OF SERVICE")
          CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL
          RIGHTS, REMEDIES, AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS
          AND EXCLUSIONS.</b
        >
        <h2>Introduction</h2>
        <p>
          Welcome to STEAMPlug Courses (the &ldquo;Site&rdquo;), which is owned
          and operated by STEAMPlug LLC, a California limited liability company
          (the &ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;,
          interchangeably).
        </p>
        <p>
          By visiting our website and accessing the information, resources,
          services, products, and tools we provide, you understand and agree to
          accept and adhere to the following terms and conditions as stated in
          these Terms of Service (hereafter referred to as &ldquo;User
          Agreement&rdquo;), along with the terms and conditions as stated in
          our Privacy Policy (as defined below).
        </p>

        <ul>
          <li>
            All visitors (&ldquo;Site Visitors&rdquo;) to the Company's website
            located at
            <kbd
              ><a id="tex2html1" href="https://courses.steamplug.com"
                >https://courses.steamplug.com</a
              ></kbd
            >
            (the &ldquo;Site&rdquo;);
          </li>
          <li>
            Anyone who downloads and/or uses (&ldquo;Platform Visitors&rdquo;)
            the Company's web or app-based software platform (the
            &ldquo;Platform&rdquo;);
          </li>
          <li>
            Anyone who enrolls for the Services of the Company by purchasing a
            Code as defined herein (&ldquo;Subscribers&rdquo;).
          </li>
        </ul>

        <p>
          Site Visitors, Platform Visitors, and Subscribers are referred to
          collectively as &ldquo;Users&rdquo; or &ldquo;you&rdquo;,
          interchangeably.
        </p>

        <p>
          We reserve the right to change this User Agreement from time to time
          without notice by posting an updated copy of this User Agreement to
          the Site. You acknowledge and agree that it is your responsibility to
          review this User Agreement periodically to familiarize yourself with
          any modifications. Your continued use of this site after such
          modifications will constitute acknowledgment and agreement of the
          modified terms and conditions.
        </p>

        <ol>
          <li>
            <span class="textbf">Responsible Use and Conduct</span>

            <br />

            <ol>
              <li>
                <span id="txt20">Resources</span>. By visiting our Website and
                accessing the information, resources, Services (as defined
                below), Platform, products, and tools we provide for you, either
                directly or indirectly (hereafter referred to as
                &ldquo;Resources&rdquo;), you agree to use these Resources only
                for the purposes intended as permitted by (a) the terms of this
                User Agreement, and (b) applicable laws, regulations and
                generally accepted online practices or guidelines.

                <br />
              </li>
              <li>
                <span id="txt21">Prohibited Usage</span>. Wherein, you
                understand that:

                <ol>
                  <li>
                    In order to access our Resources, you may be required to
                    provide certain information about yourself (such as
                    identification, contact details, etc.) as part of the
                    registration process (the &ldquo;Registration
                    Information&rdquo;), or as part of your ability to use the
                    Resources. You agree that any information you provide will
                    always be accurate, correct, and up to date.

                    <br />
                  </li>
                  <li>
                    You are responsible for maintaining the confidentiality of
                    any login information associated with any account you use to
                    access our Resources. Accordingly, you are responsible for
                    all activities that occur under your account/s.

                    <br />
                  </li>
                  <li>
                    Accessing (or attempting to access) any of our Resources by
                    any means other than through the means we provide, is
                    strictly prohibited. You specifically agree not to access
                    (or attempt to access) any of our Resources through any
                    automated, unethical or unconventional means.

                    <br />
                  </li>
                  <li>
                    Engaging in any activity that disrupts or interferes with
                    our Resources, including the servers and/or networks to
                    which our Resources are located or connected, is strictly
                    prohibited.

                    <br />
                  </li>
                  <li>
                    Attempting to copy, duplicate, reproduce, sell, trade, or
                    resell our Resources is strictly prohibited.

                    <br />
                  </li>
                  <li>
                    You are solely responsible any consequences, losses, or
                    damages that we may directly or indirectly incur or suffer
                    due to any unauthorized activities conducted by you, as
                    explained above, and may incur criminal or civil liability.

                    <br />
                  </li>
                  <li>
                    We may provide various open communication tools on our
                    website, such as blog comments, blog posts, public chat,
                    forums, message boards, newsgroups, product ratings and
                    reviews, various social media services, etc. You understand
                    that generally we do not pre-screen or monitor content
                    posted by users of these various communication tools, which
                    means that if you choose to use these tools to submit any
                    type of content to our website, then it is your personal
                    responsibility to use these tools in a responsible and
                    ethical manner. By posting information or otherwise using
                    any open communication tools as mentioned, you agree that
                    you will not upload, post, share, or otherwise distribute
                    any content that:

                    <br />

                    <ol>
                      <li>
                        Is illegal, threatening, defamatory, abusive, harassing,
                        degrading, intimidating, fraudulent, deceptive,
                        invasive, racist, or contains any type of suggestive,
                        inappropriate, or explicit language;

                        <br />
                      </li>
                      <li>
                        Infringes on any trademark, patent, trade secret,
                        copyright, or other proprietary rights of any party;

                        <br />
                      </li>
                      <li>
                        Contains any type of unauthorized or unsolicited
                        advertising;

                        <br />
                      </li>
                      <li>
                        Impersonates any person or entity, including any Company
                        employees or representatives.

                        <br />
                      </li>
                    </ol>

                    <br />
                  </li>
                </ol>

                <br />
                We have the right at our sole discretion to remove any content
                that we feel in our judgment does not comply with this User
                Agreement, along with any content that we feel is otherwise
                offensive, harmful, objectionable, inaccurate, or violates any
                third-party copyrights or trademarks. We are not responsible for
                any delay or failure in removing such content. If you post
                content that we choose to remove, you hereby consent to such
                removal, and consent to waive any claim against us.

                <br />
              </li>
              <li>
                <span id="txt26">Eligibility</span>. This is a contract between
                you and the Company. You must read and agree to these terms
                before using the Company's services. If you do not agree, you
                may not use the Service. You may use the Service only if you can
                form a binding contract with the Company, and only in compliance
                with this Agreement and all applicable local, state, national,
                and international laws, rules and regulations. You are
                responsible for the acts of others utilizing your access to the
                Service and will be held responsible for violations of the
                Service by persons who gain access to the Service using your
                account or shared access. Any use or access to the Service by
                anyone under the Minimum Age is strictly prohibited and in
                violation of this Agreement. If you are below the Minimum Age,
                you represent and warrant that you have reviewed this User
                Agreement and the Privacy Policy with your parent/guardian, and
                you further acknowledge that the Company has entered into this
                User Agreement with you on the express reliance on the truth and
                accuracy of this representation. The Service is not available to
                any Users previously removed from the Service by the Company.

                <br />
                <br />
                &ldquo;Minimum Age&rdquo; means the greater of (i) 12 years old
                or (ii) the age required by applicable law, if such law requires
                that you must be older in order for us to lawfully provide the
                Services to you without parental consent (including using of
                your personal data).

                <br />
              </li>
              <li>
                <span id="txt27">Accountholder Responsibilities</span>. You
                agree to: (1) use a strong password and keep it confidential and
                (2) not transfer any part of your Account. You are responsible
                for anything that happens through your Account unless you close
                it or report misuse. As between you and others (including your
                employer), your Account belongs to you. However, if the Services
                were purchased by another party for you to use, the party paying
                for such Service has the right to control access to and get
                reports, as applicable, on your use of such paid Service;
                however, they do not have rights to your personal Account.

                <br />
              </li>
              <li>
                <span id="txt28">Reverse Engineering</span>. You will not use
                the Services to create a product or service with features that
                are substantially similar to or that re-create the features of
                another Company product or service.

                <br />
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">Services and Payments</span>

            <br />

            <ol>
              <li>
                <span id="txt32">Limited License</span>. Subject to the terms
                and conditions of this Agreement, you are hereby granted a
                non-exclusive, limited, non-transferable, non-sublicensable,
                freely revocable license to use the Resources for your personal,
                noncommercial use only and as permitted by the features of the
                Service. The Company reserves all rights not expressly granted
                herein in the Resources and the Company Content (as defined
                below). The Company may terminate this license at any time for
                any reason or no reason.

                <br />
              </li>
              <li>
                <span id="txt33">Services</span>.

                <br />

                <ol>
                  <li>
                    <span class="textit">Scope</span>. The Company provides
                    services related to the provision, maintenance, and
                    development of the Platform for facilitating an online
                    classroom environment and related features for written and
                    audiovisual communication, document transfer, and review
                    (the &ldquo;Services&rdquo;). In order to fully and
                    completely perform the Services, the Company needs to
                    collect and process certain information and data belonging
                    to Users in accordance with the Privacy Policy as more fully
                    discussed in Section 4 below.

                    <br />
                  </li>
                  <li>
                    <span class="textit">Payments</span>. No payments are made
                    or collected through the Website or the Platform.
                    Subscribers enroll in the Services as more fully set forth
                    below.

                    <br />
                  </li>
                  <li>
                    <span class="textit">Term</span>. The Term of the Services
                    begins when Subscribers enroll for an account and continue
                    until the completion of the Course or Courses to which the
                    Subscribers are enrolled as set forth below.

                    <br />
                  </li>
                </ol>

                <br />
              </li>
              <li>
                <span id="txt39">Accounts and Enrollment</span>. Subscribers
                access the Platform with an access code (a &ldquo;Code&rdquo;)
                purchased from a providing partner college or university or
                other authorized retailer. Subscribers can enroll for an account
                as a student (a &ldquo;Student Account&rdquo;) or an instructor
                (&ldquo;Instructor Account&rdquo;, and together with Student
                Accounts &ldquo;Accounts&rdquo;) for one or more courses (each,
                a &ldquo;Course&rdquo;). Subscribers who enroll for a Student
                Account are called &ldquo;Students&rdquo; and Subscribers who
                enroll for an Instructor Account are called
                &ldquo;Instructors.&rdquo;

                <br />

                <ol>
                  <li>
                    <span class="textit"
                      >Instructor Access to Student Information</span
                    >. In order to facilitate assignment review and adherence to
                    school and classroom policies and rules, Instructors will
                    have access to all information provided and associated with
                    Student Accounts for Students enrolled in such Instructor's
                    Course.

                    <br />
                  </li>
                </ol>

                <br />
              </li>
              <li>
                <span id="txt43">Location of Service</span>. The Service is
                controlled and operated from facilities in the United States.
                the Company makes no representations that the Service is
                appropriate or available for use in other locations. Those who
                access or use the Service from other jurisdictions do so at
                their own volition and are entirely responsible for compliance
                with all applicable United States and local laws and
                regulations, including but not limited to export and import
                regulations. You may not use the Service if you are a resident
                of a country embargoed by the United States, or are a foreign
                person or entity blocked or denied by the United States
                government. Unless otherwise explicitly stated, all materials
                found on the Service are solely directed to individuals,
                companies, or other entities located in the United States.

                <br />
              </li>
              <li>
                <span id="txt44">California Residents</span>. The provider of
                services is set forth herein. If you are a California resident,
                in accordance with Cal. Civ. Code §1789.3, you may report
                complaints to the Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer
                Affairs by contacting them in writing at 1625 North Market
                Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at
                (800) 952-5210 or (916) 445-1254.

                <br />
              </li>
              <li>
                <span id="txt45">Availability</span>. We may, without prior
                notice, change the Service; stop providing the Service or
                features of the Service, to you or to Users generally; or create
                usage limits for the Service. We may permanently or temporarily
                terminate or suspend your access to the Service without notice
                and liability for any reason, including if in our sole
                determination you violate any provision of this Agreement, or
                for no reason. Upon termination for any reason or no reason, you
                continue to be bound by this Agreement.

                <br />
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">Termination of Use</span>

            <br />

            <ol>
              <li>
                <span id="txt49">Generally</span>. You agree that we may, at our
                sole discretion, suspend or terminate your access to all or part
                of the Resources with or without notice and for any reason,
                including, without limitation, breach of this User Agreement,
                and nonpayment of any Fees. Any suspected illegal, fraudulent or
                abusive activity may be grounds for terminating your
                relationship and may be referred to appropriate law enforcement
                authorities. Upon suspension or termination, your right to use
                the Resources we provide will immediately cease, and we reserve
                the right to remove or delete any information that you may have
                on file with us, including any Registration Information.
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf"
              >Data Retention, Usage, and Privacy Policy</span
            >

            <br />

            <ol>
              <li>
                <span id="txt53">Privacy Policy</span>. Your privacy is very
                important to us, which is why we've created a separate Privacy
                Policy (the &ldquo;Privacy Policy&rdquo;) in order to explain in
                detail how we collect, manage, process, secure, and store your
                private information. Our privacy policy is included under the
                scope of this User Agreement and is incorporated by reference as
                if fully set forth herein. To read our Privacy Policy in its
                entirety, click here
                <kbd
                  ><a
                    id="tex2html2"
                    href="https://courses.steamplug.com/privacy."
                    >https://courses.steamplug.com/privacy.</a
                  ></kbd
                >
                <br />
              </li>
              <li>
                <span id="txt55">Data Retention</span>. The Company has adopted
                certain practices and procedures with respect to data collected
                and retained by the Company in connection with the provision of
                the Resources to Subscribers, and records related thereto,
                including Registration Information (the &ldquo;Data&rdquo;) to
                ensure (i) the Data is adequately protected and maintained, (ii)
                that Data no longer needed by the Company or which is of no
                value is discarded at the proper time, and (iii) that Data
                belonging to Subscribers are safely and properly retained as
                needed and, when appropriate, returned or destroyed. We don't
                promise to store or keep showing any information and content
                that you've posted. You agree that we have no obligation to
                store, maintain or provide you a copy of any content or
                information that you or others provide, except to the extent
                required by applicable law and as noted in our Privacy Policy.

                <br />
              </li>
              <li>
                <span id="txt56">Document Destruction</span>. The Company will
                keep and retain information, data, and User Content you submit
                through the Platform in accordance with the record retention
                policy of your respective academic institution or in accordance
                with applicable law, whichever is longer, after which time the
                Data will be (i) archived or (ii) deleted, in the Company's sole
                discretion. If the Company elects to delete the data it will
                take reasonable efforts to delete, to remove, or to otherwise
                destroy any such Data in its possession.

                <br />
              </li>
              <li>
                <span id="txt57">Litigation Hold</span>. In the event the
                Company is served with any subpoena or request for documents or
                becomes aware of a governmental investigation or audit
                concerning the Company or a client thereof, or the commencement
                of any litigation against or concerning the Company or a client
                thereof, any further disposal of documents shall be suspended
                until such time as the Company, with the advice of counsel,
                determines otherwise. The Company shall take such steps as is
                necessary to promptly inform all staff of any suspension in the
                further disposal of documents.

                <br />
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">Company Proprietary Rights</span>

            <br />
            Except for your User Content, the Service and all materials therein
            or transferred thereby, including, without limitation, software,
            images, text, graphics, illustrations, logos, patents, trademarks,
            service marks, copyrights, photographs, audio, videos, music, and
            User Content belonging to other Users (<span class="textbf"
              >the &ldquo;the Company Content&rdquo;</span
            >), and all Intellectual Property Rights related thereto, are the
            exclusive property of the Company and its licensors (including other
            Users who post User Content to the Service). Except as explicitly
            provided herein, nothing in this Agreement shall be deemed to create
            a license in or under any such Intellectual Property Rights, and you
            agree not to sell, license, rent, modify, distribute, copy,
            reproduce, transmit, publicly display, publicly perform, publish,
            adapt, edit or create derivative works from any the Company Content.
            Use of the Company Content for any purpose not expressly permitted
            by this Agreement is strictly prohibited.

            <br />
            You may choose to or we may invite you to submit comments or ideas
            about the Service, including without limitation about how to improve
            the Service or our products (<span class="textbf"
              >&ldquo;Ideas&rdquo;</span
            >). By submitting any Idea, you agree that your disclosure is
            gratuitous, unsolicited and without restriction and will not place
            the Company under any fiduciary or other obligation, and that we are
            free to use the Idea without any additional compensation to you,
            and/or to disclose the Idea on a non-confidential basis or otherwise
            to anyone. You further acknowledge that, by acceptance of your
            submission, the Company does not waive any rights to use similar or
            related ideas previously known to the Company, or developed by its
            employees, or obtained from sources other than you.

            <br />
            The Service contains data, information, and other content not owned
            by you, such as credits offered by the Company (<span class="textbf"
              >&ldquo;the Company Property&rdquo;</span
            >). You understand and agree that regardless of terminology used,
            the Company Property represents a limited license right governed
            solely by the terms of this Agreement and available for distribution
            at the Company's sole discretion. the Company Property is not
            redeemable for any sum of money or monetary value from the Company
            at any time. You acknowledge that you do not possess any rights of
            access or rights to data stored by or on behalf of the Company on
            the Company servers, including without limitation any data
            representing or embodying any or all of the Company Property. You
            agree that the Company has the absolute right to manage, regulate,
            control, modify and/or eliminate the Company Property as it sees fit
            in its sole discretion, in any general or specific case, and that
            the Company will have no liability to you based on its exercise of
            such right. All data on the Company's servers are subject to
            deletion, alteration or transfer. N<small
              >OTWITHSTANDING ANY VALUE ATTRIBUTED TO SUCH DATA BY YOU OR ANY
              THIRD PARTY, YOU UNDERSTAND AND AGREE THAT ANY DATA, </small
            >U<small>SER HISTORY AND </small>U<small
              >SER CONTENT RESIDING ON THE </small
            >C<small
              >OMPANY'S SERVERS, MAY BE DELETED, ALTERED, MOVED OR TRANSFERRED
              AT ANY TIME FOR ANY REASON IN THE </small
            >C<small
              >OMPANY'S SOLE DISCRETION, WITH OR WITHOUT NOTICE AND WITH NO
              LIABILITY OF ANY KIND. THE </small
            >C<small
              >OMPANY DOES NOT PROVIDE OR GUARANTEE, AND EXPRESSLY DISCLAIMS,
              ANY VALUE, CASH OR OTHERWISE, ATTRIBUTED TO ANY DATA RESIDING ON
              THE </small
            >C<small>OMPANY'S SERVERS.</small>

            <br />
          </li>
          <li>
            <span class="textbf">Limitation of Liability</span>

            <br />

            <ol>
              <li>
                <span id="txt66">As-Is</span>. By using our website, you
                understand and agree that all Resources we provide are &ldquo;as
                is&rdquo; and &ldquo;as available&rdquo;. Company makes no
                representations or warranties that:

                <br />

                <ol>
                  <li>
                    the use of our Resources will meet your needs or
                    requirements;

                    <br />
                  </li>
                  <li>
                    the use of our Resources will be uninterrupted, timely,
                    secure or free from errors;

                    <br />
                  </li>
                  <li>
                    the information obtained by using our Resources will be
                    accurate or reliable; and

                    <br />
                  </li>
                  <li>
                    any defects in the operation or functionality of any
                    Resources we provide will be repaired or corrected.
                  </li>
                </ol>

                <br />
                Furthermore, you understand and agree that (i) any content
                downloaded or otherwise obtained through the use of our
                Resources is done at your own discretion and risk, and that you
                are solely responsible for any damage to your computer or other
                devices for any loss of data that may result from the download
                of such content, and (ii) no information or advice, whether
                expressed, implied, oral or written, obtained by you from the
                Company or through any Resources we provide shall create any
                warranty, guarantee, or conditions of any kind, except for those
                expressly outlined in this User Agreement.

                <br />
              </li>
              <li>
                <span id="txt69">Warranties</span>. U<small>SE OF THE </small
                >S<small>ERVICE IS AT YOUR OWN RISK. </small>T<small
                  >O THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE </small
                >S<small
                  >ERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER
                  EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, OR NON-INFRINGEMENT. </small
                >N<small
                  >O ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
                  YOU FROM </small
                >T<small>HE </small>C<small>OMPANY OR THROUGH THE </small
                >S<small
                  >ERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. </small
                >W<small>ITHOUT LIMITING THE FOREGOING, </small>T<small
                  >HE </small
                >C<small
                  >OMPANY, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS
                  DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
                  CORRECT; THAT THE </small
                >S<small>ERVICE WILL MEET YOUR REQUIREMENTS; THAT THE </small
                >S<small
                  >ERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
                  UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE
                  CORRECTED; OR THAT THE </small
                >S<small
                  >ERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. </small
                >A<small
                  >NY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
                  OF THE </small
                >S<small
                  >ERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE
                  DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR
                  USE OF THE </small
                >S<small>ERVICE.</small>

                <br />
                F<small>URTHER, </small>T<small>HE </small>C<small
                  >OMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                  RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                  OFFERED BY A THIRD PARTY THROUGH THE </small
                >S<small
                  >ERVICE OR ANY HYPERLINKED WEBSITE OR SERVICE, AND </small
                >T<small>HE </small>C<small
                  >OMPANY WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
                  TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS
                  OR SERVICES.</small
                >

                <br />
                F<small
                  >EDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO
                  NOT ALLOW THE EXCLUSION AND LIMITATIONS OF CERTAIN IMPLIED
                  WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. </small
                >T<small>HIS </small>A<small
                  >GREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO
                  HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. </small
                >T<small>HE DISCLAIMERS AND EXCLUSIONS UNDER THIS </small
                >A<small
                  >GREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY
                  APPLICABLE LAW.</small
                >

                <br />
              </li>
              <li>
                <span id="txt73">Limitation of Liability</span>. T<small
                  >O THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL </small
                >T<small>HE </small>C<small
                  >OMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES,
                  SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE,
                  INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                  INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
                  GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF
                  OR RELATING TO THE USE OF, OR INABILITY TO USE, THE </small
                >S<small>ERVICE. </small>U<small
                  >NDER NO CIRCUMSTANCES WILL </small
                >T<small>HE </small>C<small
                  >OMPANY BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY
                  RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS
                  OR USE OF THE </small
                >S<small
                  >ERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED
                  THEREIN.</small
                >

                <br />
                T<small
                  >O THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, </small
                >T<small>HE </small>C<small
                  >OMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
                  ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL
                  INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
                  FROM YOUR ACCESS TO OR USE OF OUR SERVICE; (III) ANY
                  UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
                  AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
                  INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE </small
                >S<small
                  >ERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                  THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD
                  PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY
                  LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                  POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
                  THROUGH THE </small
                >S<small>ERVICE; AND/OR (VII) </small>U<small>SER </small
                >C<small
                  >ONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF
                  ANY THIRD PARTY. </small
                >I<small>N NO EVENT SHALL </small>T<small>HE </small>C<small
                  >OMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES,
                  SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS,
                  PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR
                  COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO </small
                >T<small>HE </small>C<small
                  >OMPANY HEREUNDER OR $100.00, WHICHEVER IS GREATER.</small
                >

                <br />
                T<small
                  >HIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE
                  ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE,
                  STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF </small
                >T<small>HE </small>C<small
                  >OMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGE.</small
                >

                <br />
                S<small
                  >OME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS
                  OR EXCLUSIONS MAY NOT APPLY TO YOU. </small
                >T<small>HIS </small>A<small
                  >GREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO
                  HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. </small
                >T<small
                  >HE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY
                  UNDER THIS </small
                >A<small
                  >GREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY
                  APPLICABLE LAW.</small
                >

                <br />
              </li>
              <li>
                <span id="txt78">Indemnification</span>. You agree to defend,
                indemnify and hold harmless the Company and its subsidiaries,
                agents, licensors, managers, and other affiliated companies, and
                their employees, contractors, agents, officers and directors,
                from and against any and all claims, damages, obligations,
                losses, liabilities, costs or debt, and expenses (including but
                not limited to attorney's fees) arising from: (i) your use of
                and access to the Service, including any data or content
                transmitted or received by you; (ii) your violation of any term
                of this Agreement, including without limitation your breach of
                any of the representations and warranties above; (iii) your
                violation of any third-party right, including without limitation
                any right of privacy or Intellectual Property Rights; (iv) your
                violation of any applicable law, rule or regulation; (v) User
                Content or any content that is submitted by you including
                without limitation misleading, false, or inaccurate information;
                (vi) your willful misconduct; or (vii) any other party's access
                and use of the Service with your unique username, password or
                other appropriate security code.

                <br />
              </li>
              <li>
                <span id="txt79">Third Party Content</span>. This User Agreement
                does not apply to the websites or services of any other person
                or entity. We may provide, or third parties may provide, links
                to other worldwide websites or resources. You acknowledge and
                agree that we are not responsible for the availability of such
                external sites or resources, and do not endorse (and are not
                responsible or liable for) any content, advertising, products,
                or other materials on or available from such websites or
                resources. You further acknowledge and agree that, under no
                circumstances, will we be held responsible or liable, directly
                or indirectly, for any loss or damage that is caused or alleged
                to have been caused to you in connection with your use of, or
                reliance on, any content, advertisements, products or other
                resources available on any other website (regardless of whether
                we directly or indirectly link to such content, advertisements,
                products or other resources). You should direct any concerns
                with respect to any other website to that website's
                administrator or webmaster.

                <br />
              </li>
              <li>
                <span id="txt80">Security</span>. The Company cares about the
                integrity and security of your personal information. The Company
                uses commercially reasonable physical, managerial, and technical
                safeguards to preserve the integrity and security of your
                personal information and implement your privacy settings.
                However, we cannot guarantee that unauthorized third parties
                will never be able to defeat our security measures or use your
                personal information for improper purposes. You acknowledge that
                you provide your personal information at your own risk.

                <br />
              </li>
              <li>
                <span id="txt81">No Professional Advice</span>. If the Service
                provides professional information (for example, legal or
                financial), such information is for informational purposes only
                and should not be construed as professional advice. No action
                should be taken based upon any information contained in the
                Service. You should seek independent professional advice from a
                person who is licensed and/or qualified in the applicable area.
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">Intellectual Property</span>

            <br />

            <ol>
              <li>
                <span id="txt85">Site Content</span>. All content and materials
                available on the Site, including but not limited to text,
                graphics, website name, code, images and logos are the
                intellectual property of the Company, and are protected by
                applicable copyright and trademark law. Any inappropriate use,
                including but not limited to the reproduction, distribution,
                display or transmission of any content on this site is strictly
                prohibited, unless specifically authorized by the Company.

                <br />
              </li>
              <li>
                <span id="txt86">Your Content</span>. The Service allows Users
                to submit, post, display, provide, or otherwise make available
                content such as profile information, comments, questions, source
                code, logs, and other content or information (any such materials
                a User submits, posts, displays, provides, or otherwise makes
                available on the Service is referred to as
                <span class="textbf">&ldquo;User Content&rdquo;</span>).

                <br />

                <ol>
                  <li>
                    W<small>E CLAIM NO OWNERSHIP RIGHTS OVER </small>U<small
                      >SER </small
                    >C<small>ONTENT CREATED BY YOU. </small>T<small>HE </small
                    >U<small>SER </small>C<small
                      >ONTENT YOU CREATE REMAINS YOURS.</small
                    >However, you understand that certain portions of the
                    Service may allow other Users to view, edit, share, and/or
                    otherwise interact with your User Content. By providing or
                    sharing User Content through the Service, you agree to allow
                    others to view, edit, share, and/or interact with your User
                    Content in accordance with your settings and this Agreement.
                    Company has the right (but not the obligation) in its sole
                    discretion to remove any User Content that is shared via the
                    Service.

                    <br />
                  </li>
                  <li>
                    User Content you submit in response to assignments from your
                    Instructors may be reviewed by the Company, by your
                    Instructor or by your school for plagiarism, and will be
                    subject to your school's policies on academic honesty and
                    plagiarism.

                    <br />
                  </li>
                  <li>
                    By submitting, posting, displaying, providing, or otherwise
                    making available any User Content on or through the Service,
                    you expressly grant, and you represent and warrant that you
                    have all rights necessary to grant, to the Company a
                    royalty-free, sublicensable, transferable, perpetual,
                    irrevocable, non-exclusive, worldwide license to use,
                    reproduce, modify, publish, list information regarding,
                    edit, translate, distribute, syndicate, publicly perform,
                    publicly display, and make derivative works of all such User
                    Content and your name, voice, and/or likeness as contained
                    in your User Content, in whole or in part, and in any form,
                    media or technology, whether now known or hereafter
                    developed, for use in connection with the Service and the
                    Company's (and its successors' and affiliates') business,
                    including without limitation for promoting and
                    redistributing part or all of the Service (and derivative
                    works thereof) in any media formats and through any media
                    channels.

                    <br />
                  </li>
                  <li>
                    You also hereby grant each of the Company and each
                    Instructor User of the Service a non-exclusive license to
                    access your User Content through the Service, and to use,
                    reproduce, distribute, display and perform such User Content
                    as permitted through the functionality of the Service and
                    under this Agreement.

                    <br />
                  </li>
                  <li>
                    Your Instructor will have access to all of your User
                    Content, including without limitation every keystroke when
                    entering text, responses, code, assignment, or any other
                    input into the Platform. You acknowledge and agree that this
                    access is essential to the proper administration of the
                    classroom, the curriculum, and school policy and that this
                    access is fully and completely covered by the license
                    granted under Section 7(b)(iv) above.
                  </li>
                </ol>

                <br />
              </li>
              <li>
                For the purposes of this Agreement,
                <span class="textbf"
                  >&ldquo;Intellectual Property Rights&rdquo;</span
                >
                means all patent rights, copyright rights, mask work rights,
                moral rights, rights of publicity, trademark, trade dress and
                service mark rights, goodwill, trade secret rights and other
                intellectual property rights as may now exist or hereafter come
                into existence, and all applications therefore and
                registrations, renewals and extensions thereof, under the laws
                of any state, country, territory or other jurisdiction.

                <br />
                In connection with your User Content, you affirm, represent and
                warrant the following:

                <br />

                <ul>
                  <li>
                    You have the written consent of each and every identifiable
                    natural person in the User Content, if any, to use such
                    person's name or likeness in the manner contemplated by the
                    Service and this Agreement, and each such person has
                    released you from any liability that may arise in relation
                    to such use.

                    <br />
                  </li>
                  <li>
                    You have obtained and are solely responsible for obtaining
                    all consents as may be required by law to post any User
                    Content relating to third parties.

                    <br />
                  </li>
                  <li>
                    Your User Content and the Company's use thereof as
                    contemplated by this Agreement and the Service will not
                    violate any law or infringe any rights of any third party,
                    including but not limited to any Intellectual Property
                    Rights and privacy rights.

                    <br />
                  </li>
                  <li>
                    the Company may exercise the rights to your User Content
                    granted under this Agreement without liability for payment
                    of any guild fees, residuals, payments, fees, or royalties
                    payable under any collective bargaining agreement or
                    otherwise.

                    <br />
                  </li>
                  <li>
                    To the best of your knowledge, all your User Content and
                    other information that you provide to us is truthful and
                    accurate.
                  </li>
                </ul>

                <br />
                The Company takes no responsibility and assumes no liability for
                any User Content that you or any other User or third-party
                posts, sends, or otherwise makes available over the Service. You
                shall be solely responsible for your User Content and the
                consequences of posting, publishing it, sharing it, or otherwise
                making it available on the Service, and you agree that we are
                only acting as a passive conduit for your online distribution
                and publication of your User Content. You understand and agree
                that you may be exposed to User Content that is inaccurate,
                objectionable, inappropriate for children, or otherwise unsuited
                to your purpose, and you agree that the Company shall not be
                liable for any damages you allege to incur as a result of or
                relating to any User Content.

                <br />
              </li>
              <li>
                <span id="txt94">License</span>. We do not assume any liability
                for any content posted by you or any other third-party users of
                our website. However, any content posted by you using any open
                communication tools on our website, provided that it doesn't
                violate or infringe on any third party copyrights or trademarks,
                becomes the property of the Company and as such, gives us a
                perpetual, irrevocable, worldwide, royalty-free, exclusive
                license to reproduce, modify, adapt, translate, publish,
                publicly display and/or distribute as we see fit. For the
                avoidance of doubt, this does not refer to Registration
                Information. All Registration Information is covered by our
                Privacy Policy.

                <br />
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">Dispute Resolution</span>

            <br />

            <ol>
              <li>
                <span id="txt98">Disputes with Other Users</span>. You are
                solely responsible for your interactions with other Users. We
                reserve the right, but have no obligation, to monitor disputes
                between you and other Users. the Company shall have no liability
                for your interactions with other Users, or for any User's action
                or inaction.

                <br />
              </li>
              <li>
                <span id="txt99">Arbitration</span>. Any disagreement or dispute
                regarding or arising out of this Agreement, except a threat to
                or breach of Section 7 hereof (which threat or breach shall be
                subject to remedies at law or at equity in a court of competent
                jurisdiction, including but not limited to injunctive relief)
                shall be subject the following dispute resolution process. In
                the event of such a disagreement or dispute, the Parties shall
                first attempt to negotiate directly, in good faith, a
                resolution. Should the Parties not achieve resolution by such
                means, the Parties shall submit to third-party mediation by a
                reputable mediation service, or, if the Parties are unable to
                agree upon a mediator, by a mediator selected by the American
                Arbitration Association. If such mediation fails, the Parties
                may invoke arbitration. Such arbitration shall be held in Merced
                County, California and shall be conducted by an arbitrator
                agreed to by the Parties, or, if the Parties are unable to agree
                upon an arbitrator, by an arbitrator provided by the American
                Arbitration Association. Company may require that such an
                arbitrator sign a certification stating that said arbitrator is
                not acquainted with either of the Parties or either of the
                Parties' counsel.

                <br />
              </li>
              <li>
                <span id="txt100">Attorney's Fees</span>. In the event the
                Company brings any action at law or at equity for the
                enforcement of any rights hereunder or in connection with any
                claim, controversy, or dispute arising from or in connection
                with this Agreement, the Company shall be entitled to collect
                from the Client, in addition to any damages, all costs, fees,
                and expenses incurred in such dispute, including but not limited
                to reasonable attorneys' fees.

                <br />
              </li>
              <li>
                <span id="txt101">Waiver of Jury Trial</span>. EACH PARTY WAIVES
                ITS RESPECTIVE RIGHTS TO A TRIAL BY JURY OF ANY CLAIM OR CAUSE
                OF ACTION BASED UPON OR ARISING OUT OF OR RELATED TO THIS
                AGREEMENT OR THE TRANSACTIONS CONTEMPLATED HEREBY IN ANY ACTION,
                PROCEEDING OR OTHER LITIGATION OF ANY TYPE BROUGHT BY ANY PARTY
                AGAINST THE OTHER PARTY OR ANY AFFILIATE OF SUCH OTHER PARTY,
                WHETHER WITH RESPECT TO CONTRACT CLAIMS, TORT CLAIMS OR
                OTHERWISE. THE PARTIES AGREE THAT ANY SUCH CLAIM OR CAUSE OF
                ACTION SHALL BE TRIED BY A COURT TRIAL WITHOUT A JURY. WITHOUT
                LIMITING THE FOREGOING, THE PARTIES FURTHER AGREE THAT THEIR
                RESPECTIVE RIGHT TO A TRIAL BY JURY IS WAIVED BY OPERATION OF
                THIS SECTION AS TO ANY ACTION, COUNTERCLAIM OR OTHER PROCEEDING
                WHICH SEEKS, IN WHOLE OR IN PART, TO CHALLENGE THE VALIDITY OR
                ENFORCEABILITY OF THIS AGREEMENT OR ANY PROVISION HEREOF. THIS
                WAIVER SHALL APPLY TO ANY SUBSEQUENT AMENDMENTS, RENEWALS,
                SUPPLEMENTS OR MODIFICATIONS TO THIS AGREEMENT.
                <span class="textbf"
                  >EACH PARTY ACKNOWLEDGES THAT IT HAS HAD THE OPPORTUNITY TO
                  CONSULT THE ADVICE OF COMPETENT COUNSEL.</span
                >

                <br />
              </li>
              <li>
                <span id="txt103">Waiver of Class Action</span>. W<small
                  >ITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF
                  WHETHER THEY HAVE OBTAINED OR USED THE </small
                >S<small
                  >ERVICE FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL </small
                >C<small
                  >LAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY,
                  AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                  ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR
                  OTHER REPRESENTATIVE PROCEEDING. </small
                >T<small
                  >HIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE
                  OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE
                  PERSON'S </small
                >C<small>LAIMS. </small>Y<small
                  >OU AGREE THAT, BY ENTERING INTO THIS </small
                >A<small>GREEMENT, YOU AND </small>T<small>HE </small>C<small
                  >OMPANY ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
                  PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE
                  ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF
                  ANY KIND.</small
                >
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">DMCA Notice</span>

            <br />
            Since we respect artist and content owner rights, it is the
            Company's policy to respond to alleged infringement notices that
            comply with the Digital Millennium Copyright Act of 1998
            (&ldquo;DMCA&rdquo;). If you believe that your copyrighted work has
            been copied in a way that constitutes copyright infringement and is
            accessible via the Service, please notify the Company's copyright
            agent as set forth in the DMCA. For your complaint to be valid under
            the DMCA, you must provide the following information in writing:

            <br />

            <ol>
              <li>
                An electronic or physical signature of a person authorized to
                act on behalf of the copyright owner;

                <br />
              </li>
              <li>
                Identification of the copyrighted work that you claim has been
                infringed;

                <br />
              </li>
              <li>
                Identification of the material that is claimed to be infringing
                and where it is located on the Service;

                <br />
              </li>
              <li>
                Information reasonably sufficient to permit the Company to
                contact you, such as your address, telephone number, and, e-mail
                address;

                <br />
              </li>
              <li>
                A statement that you have a good faith belief that use of the
                material in the manner complained of is not authorized by the
                copyright owner, its agent, or law; and

                <br />
              </li>
              <li>
                <p>
                  A statement, made under penalty of perjury, that the above
                  information is accurate, and that you are the copyright owner
                  or are authorized to act on behalf of the owner.
                </p>

                <p>
                  The above information must be submitted to the following DMCA
                  Agent:
                </p>

                Attn: DMCA Notice

                <br />
                STEAMPlug, LLC

                <br />
                Address: [ STEAMPlug to Provide Preferred]

                <br />
                Phone: [ STEAMPlug to Provide Preferred]

                <br />
                Email: info@steamplug.com

                <p>
                  U<small
                    >NDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE
                    MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL
                    PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING
                    MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS' FEES.</small
                  >
                </p>

                <p>
                  Please note that this procedure is exclusively for notifying
                  the Company and its affiliates that your copyrighted material
                  has been infringed. The preceding requirements are intended to
                  comply with the Company’s rights and obligations under the
                  DMCA, including 17 U.S.C. §512(c), but do not constitute legal
                  advice. It may be advisable to contact an attorney regarding
                  your rights and obligations under the DMCA and other
                  applicable laws.
                </p>
                <p>
                  In accordance with the DMCA and other applicable law, the
                  Company has adopted a policy of terminating, in appropriate
                  circumstances, Users who are deemed to be repeat infringers.
                  the Company may also at its sole discretion limit access to
                  the Service and/or terminate the Services of any Users who
                  infringe any intellectual property rights of others, whether
                  or not there is any repeat infringement.
                </p>
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">Miscellaneous Provisions</span>

            <br />

            <ol>
              <li>
                <span id="txt112">Governing Law</span>. This Agreement and any
                claim or controversy arising hereunder or in connection herewith
                shall be governed by and construed in accordance with the
                domestic laws of the State of California without giving effect
                to any choice or conflict of law provision or rule (whether of
                the State of California or any other jurisdiction) that would
                cause the application of the laws of any jurisdiction other than
                the State of California.

                <br />
              </li>
              <li>
                <span id="txt113">Jurisdiction and Venue</span>. Subject in all
                ways to Section 8(b) hereof, each of the Parties irrevocably
                submits to the jurisdiction of any State or Federal court
                sitting in Merced County, California (collectively, the
                &ldquo;<span id="txt114">Designated Courts</span>&rdquo;) for
                the enforcement of the final determination of any arbitration
                brought pursuant to Section 8(b) hereof and, as necessary, for
                the enforcement of any injunctive relief or enforcement with
                respect to the applicable provisions hereof. Each of the Parties
                further agrees that service of any process, summons, notice or
                other document by U.S. registered mail or email to such Party’s
                respective address as provided in connection with the Services
                shall be effective service of process for any action, suit or
                proceeding in the Designated Courts or in connection with any
                arbitration action with respect to any matters to which the
                Parties have submitted to jurisdiction as set forth herein. Each
                of the Parties irrevocably and unconditionally waives any
                objection to the laying of venue of any dispute, action, suit or
                proceeding arising out of this Agreement or the transactions
                contemplated hereby in any arbitration proceeding or court
                located in Merced County, California and hereby further
                irrevocably and unconditionally waives and agrees not to plead
                or claim in any such arbitration proceeding or Designated Court
                that any such dispute, action, suit or proceeding brought in any
                such Court or arbitration proceeding has been brought in an
                inconvenient forum or venue.

                <br />
              </li>
              <li>
                <span id="txt115">Severability</span>. If any provision of this
                User Agreement shall be unlawful, void, or for any reason
                unenforceable, then that provision shall be deemed severable
                from these terms and shall not affect the validity and
                enforceability of any remaining provisions.

                <br />
              </li>
              <li>
                <span id="txt116">Amendments</span>. We may modify this
                Contract, our Privacy Policy and our Cookies Policy from time to
                time. If we make material changes to it, we will provide you
                notice through our Site, or by other means, to provide you the
                opportunity to review the changes before they become effective.
                We agree that changes cannot be retroactive. If you object to
                any changes, you may close your account and terminate your use
                of the Resources. Your continued use of our Resources after we
                publish or send a notice about our changes to these terms means
                that you are consenting to the updated terms as of their
                effective date.

                <br />
              </li>
              <li>
                <span id="txt117">Assignment</span>. We may assign our rights
                and obligations under this User Agreement. This User Agreement
                will inure to the benefit of our successors, assigns and
                licensees.

                <br />
              </li>
              <li>
                <span id="txt118">Waiver</span>. The failure of either party to
                insist upon or enforce the strict performance of the other party
                with respect to any provision of this User Agreement, or to
                exercise any right under this User Agreement, will not be
                construed as a waiver or relinquishment to any extent of such
                party’s right to assert or rely upon any such provision or right
                in that or any other instance; rather, the same will be and
                remain in full force and effect.

                <br />
              </li>
              <li>
                <span id="txt119">Business Days</span>. If any time period for
                giving notice or taking action hereunder expires on a day which
                is a Saturday, Sunday or legal holiday in Merced County,
                California, the time period shall automatically be extended to
                the business day immediately following such Saturday, Sunday or
                legal holiday.

                <br />
              </li>
              <li>
                <span id="txt120">Construction</span>. In the event an ambiguity
                or question of intent or interpretation arises, this User
                Agreement shall be construed as if drafted jointly by the
                Parties and no presumption or burden of proof shall arise
                favoring or disfavoring any Party by virtue of the authorship of
                any of the provisions of this Agreement. Any reference to any
                law shall be deemed also to refer to all rules and regulations
                promulgated thereunder, unless the context requires otherwise.
                The word “including” shall mean including without limitation.
                Pronouns shall be deemed to refer to the masculine, feminine,
                and neutral and to the singular or plural as context requires.
                The section headings are included for convenience purposes only
                and shall not affect the meaning or construction of the
                substantive provisions hereof. The Recitals are incorporated
                into this Agreement by reference as if fully set forth herein.
                Each of the Company and you are referred to as a “Party” and
                together, the “Parties”.
              </li>
            </ol>

            <br />
          </li>
          <li>
            <span class="textbf">Contact Information</span>

            <br />
            If you have any questions or comments about this User Agreement as
            outlined above, you can contact us at: info@steamplug.com

            <br />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useBreadcrumb from "@/composables/useBreadcrumb";
import { defineComponent } from "vue";
import { nanoid } from "nanoid";

export default defineComponent({
  setup() {
    const { setBreadcrumbs } = useBreadcrumb();

    setBreadcrumbs([
      { id: nanoid(), label: "Home", routeLocation: { name: "Courses" } },
      {
        id: nanoid(),
        label: "Terms of Use",
        routeLocation: {
          name: "Terms",
        },
      },
    ]);
  },
});
</script>

<style scoped>
ol ol {
  list-style-type: lower-alpha;
}

ol ol ol {
  list-style-type: lower-roman;
}

ol ol ol ol {
  list-style-type: upper-alpha;
}

ul {
  list-style-type: disc;
}

html,
address,
blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
frame,
frameset,
h1,
h2,
h3,
h4,
h5,
h6,
noframes,
ol,
p,
ul,
center,
dir,
hr,
menu,
pre {
  display: block;
  unicode-bidi: embed;
}
li {
  display: list-item;
}
head {
  display: none;
}
table {
  display: table;
}
tr {
  display: table-row;
}
thead {
  display: table-header-group;
}
tbody {
  display: table-row-group;
}
tfoot {
  display: table-footer-group;
}
col {
  display: table-column;
}
colgroup {
  display: table-column-group;
}
td,
th {
  display: table-cell;
}
caption {
  display: table-caption;
}
th {
  font-weight: bolder;
  text-align: center;
}
caption {
  text-align: center;
}
body {
  margin: 8px;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}
h3 {
  font-size: 1.17em;
  margin: 0.83em 0;
}
h4,
p,
blockquote,
ul,
fieldset,
form,
ol,
dl,
dir,
menu {
  margin: 1.12em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.5em 0;
}
h6 {
  font-size: 0.75em;
  margin: 1.67em 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: bolder;
}
blockquote {
  margin-left: 40px;
  margin-right: 40px;
}
i,
cite,
em,
var,
address {
  font-style: italic;
}
pre,
tt,
code,
kbd,
samp {
  font-family: monospace;
}
pre {
  white-space: pre;
}
button,
textarea,
input,
select {
  display: inline-block;
}
big {
  font-size: 1.17em;
}
small,
sub,
sup {
  font-size: 0.83em;
}
sub {
  vertical-align: sub;
}
sup {
  vertical-align: super;
}
table {
  border-spacing: 2px;
}
thead,
tbody,
tfoot {
  vertical-align: middle;
}
td,
th,
tr {
  vertical-align: inherit;
}
s,
strike,
del {
  text-decoration: line-through;
}
hr {
  border: 1px inset;
}
ol,
ul,
dir,
menu,
dd {
  margin-left: 40px;
}
ol {
  list-style-type: decimal;
}
ol ul,
ul ol,
ul ul,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}
u,
ins {
  text-decoration: underline;
}
br:before {
  content: "\A";
  white-space: pre-line;
}
center {
  text-align: center;
}
:link,
:visited {
  text-decoration: underline;
}
:focus {
  outline: thin dotted invert;
}

/* Begin bidirectionality settings (do not change) */
BDO[DIR="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}
BDO[DIR="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

*[DIR="ltr"] {
  direction: ltr;
  unicode-bidi: embed;
}
*[DIR="rtl"] {
  direction: rtl;
  unicode-bidi: embed;
}

@media print {
  h1 {
    page-break-before: always;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }
}

li {
  margin-top: 1rem;
}

span.textbf {
  font-weight: bold;
}
span.textit {
  font-style: italic;
}
#txt100 {
  text-decoration: underline;
}
#txt101 {
  text-decoration: underline;
}
#txt103 {
  text-decoration: underline;
}
#txt112 {
  text-decoration: underline;
}
#txt113 {
  text-decoration: underline;
}
#txt114 {
  text-decoration: underline;
}
#txt115 {
  text-decoration: underline;
}
#txt116 {
  text-decoration: underline;
}
#txt117 {
  text-decoration: underline;
}
#txt118 {
  text-decoration: underline;
}
#txt119 {
  text-decoration: underline;
}
#txt120 {
  text-decoration: underline;
}
#txt20 {
  text-decoration: underline;
}
#txt21 {
  text-decoration: underline;
}
#txt26 {
  text-decoration: underline;
}
#txt27 {
  text-decoration: underline;
}
#txt28 {
  text-decoration: underline;
}
#txt32 {
  text-decoration: underline;
}
#txt33 {
  text-decoration: underline;
}
#txt39 {
  text-decoration: underline;
}
#txt43 {
  text-decoration: underline;
}
#txt44 {
  text-decoration: underline;
}
#txt45 {
  text-decoration: underline;
}
#txt49 {
  text-decoration: underline;
}
#txt53 {
  text-decoration: underline;
}
#txt55 {
  text-decoration: underline;
}
#txt56 {
  text-decoration: underline;
}
#txt57 {
  text-decoration: underline;
}
#txt66 {
  text-decoration: underline;
}
#txt69 {
  text-decoration: underline;
}
#txt73 {
  text-decoration: underline;
}
#txt78 {
  text-decoration: underline;
}
#txt79 {
  text-decoration: underline;
}
#txt80 {
  text-decoration: underline;
}
#txt81 {
  text-decoration: underline;
}
#txt85 {
  text-decoration: underline;
}
#txt86 {
  text-decoration: underline;
}
#txt94 {
  text-decoration: underline;
}
#txt98 {
  text-decoration: underline;
}
#txt99 {
  text-decoration: underline;
}
</style>
