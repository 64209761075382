<template>
  <div
    class="h-7 px-4 flex flex-row items-center text-xs border-b flex-shrink-0"
    style="background-color: rgb(250, 250, 250)"
  >
    <div class="flex-grow flex flex-row overflow-x-scroll hide-scrollbar">
      <div
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.id"
        class="flex flex-row items-center"
      >
        <router-link
          :to="breadcrumb.routeLocation || breadcrumb.url"
          class="text-gray-600 whitespace-nowrap"
        >
          {{ breadcrumb.label }}
        </router-link>
        <icon
          v-if="index !== breadcrumbs.length - 1"
          :class="'mx-2 mt-0.5'"
          :name="'chevron-right'"
          :height="'12'"
          :width="'12'"
        />
      </div>
    </div>

    <div
      class="flex flex-row items-center justify-center ml-4 rounded px-2 text-xs bg-yellow-600 text-white"
      title="Following instructor"
      v-if="!canStartFollowSession && followSessionStarted"
    >
      <icon :name="'broadcast'" :color="'rgb(255, 255, 255)'" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import useBreadcrumb from "@/composables/useBreadcrumb";
import Icon from "@/components/Icon.vue";
import useFollowMode from "@/composables/useFollowMode";

export default defineComponent({
  components: {
    Icon,
  },
  setup() {
    const { canStartFollowSession, followSessionStarted } = useFollowMode();
    const { breadcrumbs } = useBreadcrumb();

    return {
      breadcrumbs,
      canStartFollowSession,
      followSessionStarted,
    };
  },
});
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
