<template>
  <div
    class="bg-black opacity-75 absolute top-0 left-0 right-0 bottom-0 z-10"
    v-if="showPrompt"
  ></div>

  <div
    class="absolute top-1/4 left-0 right-0 w-96 ml-auto mr-auto border bg-white z-20 rounded shadow-lg"
    v-if="showPrompt"
  >
    <div class="flex flex-col">
      <div class="flex flex-row items-center h-8 border-b px-2">
        <p class="text-sm font-bold">Inactivity notice</p>
      </div>
      <div class="flex flex-row px-2 py-4 border-b">
        <p class="text-sm">
          You will be automatically signed out in
          {{ signOutGrace.get() }} seconds.
        </p>
      </div>
      <div class="flex flex-row items-center justify-end px-2 py-2">
        <button
          class="bg-gray-500 text-white px-3 py-1 rounded ml-2 text-xs"
          @click="signOut"
        >
          Sign out now
        </button>
        <button
          class="bg-blue-500 text-white px-3 py-1 rounded ml-2 text-xs"
          @click="cancel"
        >
          Extend session
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref, onUnmounted } from "vue";
import { useIdle, useTimestamp, useCounter } from "@vueuse/core";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { idle, lastActive } = useIdle(5000);
    const { timestamp: now } = useTimestamp();
    const signOutGrace = useCounter();
    const showPrompt = ref<boolean>(false);
    let interval: any = null;
    const graceTimeSec = 60;
    signOutGrace.set(graceTimeSec);
    const router = useRouter();

    const promptAfter = 1140; // seconds (19 minutes)

    const idledFor = computed(() =>
      Math.abs(Math.floor((now.value - lastActive.value) / 1000))
    );

    onUnmounted(() => {
      showPrompt.value = false;
      clearInterval(interval);
      signOutGrace.reset(graceTimeSec);
    });

    watch(idledFor, () => {
      if (idledFor.value >= promptAfter && showPrompt.value == false) {
        if (idledFor.value >= promptAfter + graceTimeSec) {
          signOut();
          return;
        }

        showPrompt.value = true;

        interval = setInterval(() => {
          signOutGrace.dec();
        }, 1000);
      }
    });

    watch(signOutGrace.count, (newVal) => {
      if (newVal === 0) {
        signOut();
      }
    });

    function cancel() {
      showPrompt.value = false;
      clearInterval(interval);
      signOutGrace.reset(60);
    }

    function signOut() {
      showPrompt.value = false;
      clearInterval(interval);
      router.push({ name: "SignOut" });
    }

    return { idledFor, promptAfter, signOutGrace, showPrompt, cancel, signOut };
  },
});
</script>
