import firebase from "firebase";
import { ref } from "vue";

const user = ref<firebase.User>();
const userSignedIn = ref(false);
const returnToAfterSignIn = ref("/");

export default function useAuth() {
  async function getToken() {
    return user.value?.getIdToken();
  }

  return {
    user,
    userSignedIn,
    returnToAfterSignIn,
    getToken,
  };
}
