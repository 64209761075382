import { computed, ref } from "vue";

import { db } from "@/firebaseManager";

import { Workspace } from "@/types";
import useAuth from "./useAuth";

const workspace = ref<Workspace>();
const workspaceUnsub = ref();
const loadingWorkspace = ref(false);
const unauthorized = ref(false);

export default function useWorkspace() {
  const { user } = useAuth();

  const isClassWorkspace = computed(() => {
    return workspace.value?.type === "class";
  });

  const amInstructor = computed(() => {
    return workspace.value?.instructorIds.includes(user.value?.uid ?? "");
  });

  const isDisabledDuringClass = computed(() => {
    if (!workspace.value && !user.value) {
      return true;
    } else if (workspace.value && workspace.value.type === "class") {
      if (
        workspace.value.instructorIds.includes(user.value?.uid ?? "") ||
        workspace.value.taIds.includes(user.value?.uid ?? "")
      ) {
        return false;
      }

      return true;
    }

    return false;
  });

  async function loadWorkspace(workspaceId: string) {
    return db
      .collection("workspaces")
      .doc(workspaceId)
      .get()
      .then((docSnap) => {
        return { id: docSnap.id, ...docSnap.data() } as Workspace;
      });
  }

  function watchWorkspace(workspaceId: string) {
    // TODO: Maybe put a safeguard for multiple instances?
    workspaceUnsub.value = db
      .collection("workspaces")
      .doc(workspaceId)
      .onSnapshot(
        (docSnap) => {
          if (docSnap.exists) {
            workspace.value = {
              id: docSnap.id,
              ...docSnap.data(),
            } as Workspace;
          }
        },
        (err) => {
          loadingWorkspace.value = false;

          if (err.code === "permission-denied") {
            unauthorized.value = true;
          } else {
            // TODO: It's a different error, but don't have a screen for it
            console.error(err.code, err.message);
            unauthorized.value = true;
          }
        }
      );
  }

  function unwatchWorkspace() {
    if (workspaceUnsub.value) {
      workspaceUnsub.value();
    }

    workspace.value = undefined;
  }

  return {
    workspace,

    loadWorkspace,
    watchWorkspace,
    unwatchWorkspace,

    isDisabledDuringClass,
    loadingWorkspace,
    unauthorized,
    isClassWorkspace,
    amInstructor,
  };
}
