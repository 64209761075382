
import { defineComponent } from "vue";
import useBreadcrumb from "@/composables/useBreadcrumb";
import Icon from "@/components/Icon.vue";
import useFollowMode from "@/composables/useFollowMode";

export default defineComponent({
  components: {
    Icon,
  },
  setup() {
    const { canStartFollowSession, followSessionStarted } = useFollowMode();
    const { breadcrumbs } = useBreadcrumb();

    return {
      breadcrumbs,
      canStartFollowSession,
      followSessionStarted,
    };
  },
});
