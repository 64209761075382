import { computed } from "vue";

import { db } from "@/firebaseManager";

import useAuth from "./useAuth";
import useWorkspace from "./useWorkspace";

export default function useFollowMode() {
  const { user } = useAuth();
  const { workspace } = useWorkspace();

  const canStartFollowSession = computed(() => {
    if (workspace.value?.type === "class") {
      if (workspace.value.instructorIds.includes(user.value?.uid ?? "")) {
        return true;
      }
    }

    return false;
  });

  const followSessionStarted = computed(() => {
    if (
      !workspace.value ||
      workspace.value?.followSessionLeaderId === undefined
    ) {
      return false;
    }

    if (workspace.value?.followSessionLeaderId !== "") {
      return true;
    }

    return false;
  });

  const amFollowSessionLeader = computed(() => {
    if (
      !workspace.value ||
      workspace.value.followSessionLeaderId === undefined
    ) {
      return false;
    }

    return workspace.value.followSessionLeaderId === user.value?.uid;
  });
  const amFollowSessionFollower = computed(() => {
    if (
      !workspace.value ||
      workspace.value.followSessionLeaderId === undefined
    ) {
      return false;
    }

    return (
      workspace.value.followSessionLeaderId !== "" &&
      workspace.value.followSessionLeaderId !== user.value?.uid
    );
  });

  const leaderUserId = computed(() => {
    return workspace.value?.followSessionLeaderId;
  });

  const isDisabledDuringFollowSession = computed(() => {
    if (amFollowSessionFollower.value) {
      return true;
    }

    return false;
  });

  function startFollowSession(workspaceId: string) {
    db.collection("workspaces")
      .doc(workspaceId)
      .update({
        followSessionLeaderId: user.value?.uid,
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function stopFollowSession(workspaceId: string) {
    db.collection("workspaces")
      .doc(workspaceId)
      .update({
        followSessionLeaderId: "",
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return {
    startFollowSession,
    stopFollowSession,

    followSessionStarted,
    amFollowSessionLeader,
    amFollowSessionFollower,
    canStartFollowSession,
    leaderUserId,
    isDisabledDuringFollowSession,
  };
}
