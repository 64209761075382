
import { computed, defineComponent, watch, ref, onUnmounted } from "vue";
import { useIdle, useTimestamp, useCounter } from "@vueuse/core";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { idle, lastActive } = useIdle(5000);
    const { timestamp: now } = useTimestamp();
    const signOutGrace = useCounter();
    const showPrompt = ref<boolean>(false);
    let interval: any = null;
    const graceTimeSec = 60;
    signOutGrace.set(graceTimeSec);
    const router = useRouter();

    const promptAfter = 1140; // seconds (19 minutes)

    const idledFor = computed(() =>
      Math.abs(Math.floor((now.value - lastActive.value) / 1000))
    );

    onUnmounted(() => {
      showPrompt.value = false;
      clearInterval(interval);
      signOutGrace.reset(graceTimeSec);
    });

    watch(idledFor, () => {
      if (idledFor.value >= promptAfter && showPrompt.value == false) {
        if (idledFor.value >= promptAfter + graceTimeSec) {
          signOut();
          return;
        }

        showPrompt.value = true;

        interval = setInterval(() => {
          signOutGrace.dec();
        }, 1000);
      }
    });

    watch(signOutGrace.count, (newVal) => {
      if (newVal === 0) {
        signOut();
      }
    });

    function cancel() {
      showPrompt.value = false;
      clearInterval(interval);
      signOutGrace.reset(60);
    }

    function signOut() {
      showPrompt.value = false;
      clearInterval(interval);
      router.push({ name: "SignOut" });
    }

    return { idledFor, promptAfter, signOutGrace, showPrompt, cancel, signOut };
  },
});
