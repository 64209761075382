import { nanoid } from "nanoid";
import {
  createRouter,
  createWebHistory,
  LocationQuery,
  RouteParams,
  RouteRecordRaw,
} from "vue-router";

import SignIn from "@/views/SignIn.vue";
import SignOut from "@/views/SignOut.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";

import useAuth from "@/composables/useAuth";
import useBreadcrumb from "@/composables/useBreadcrumb";
import useWorkspace from "@/composables/useWorkspace";

const { userSignedIn, returnToAfterSignIn } = useAuth();
const { setBreadcrumbs } = useBreadcrumb();
const { loadWorkspace } = useWorkspace();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ShutdownNotice",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: true },
  },

  {
    path: "/activate",
    name: "Activate",
    component: () => import("@/views/Activate.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/sign-out",
    name: "SignOut",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/redeem/:accessCodeId",
    name: "Redeem",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: false },
  },
  {
    path: "/",
    name: "Courses",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: false },
  },
  {
    path: "/courses/:courseId",
    name: "Course",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: false },
  },
  {
    path: "/projects/:projectId",
    name: "Project",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: false },
  },
  {
    path: "/workspaces/:workspaceId",
    name: "WorkspaceViewer",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: false },
  },
  {
    path: "/workspaces/:workspaceId/whiteboard",
    name: "Whiteboard",
    alias: "/workspaces/:workspaceId/w",
    component: () => import("@/views/ShutdownView.vue"),
    meta: { isPublic: false },
  },

  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: { isPublic: true },
  },

  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    meta: { isPublic: true },
  },

  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("@/views/404.vue"),
    meta: { isPublic: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  buildBreadcrumbs(to.name?.toString() ?? "", to.params, to.query);

  const isPublic = to.matched.some((x) => x.meta.isPublic);

  if (!isPublic && !userSignedIn.value) {
    returnToAfterSignIn.value = to.fullPath ?? "Courses";

    next({ name: "SignIn" });
  } else {
    next();
  }
});

router.onError((err) => {
  console.error(err);
});

async function buildBreadcrumbs(
  routeName: string,
  params: RouteParams,
  queryParams: LocationQuery
) {
  switch (routeName) {
    case "Courses":
      setBreadcrumbs([
        { id: nanoid(), label: "Home", routeLocation: { name: "Courses" } },
      ]);
      break;
    case "Course":
      // TODO: Need to make a composable for course first.
      break;
    case "WorkspaceViewer":
      const workspaceId = params.workspaceId as string;
      const workspace = await loadWorkspace(workspaceId);
      const fullCourseName = `${workspace.courseDetails.code} ${workspace.courseDetails.name} - ${workspace.courseDetails.semester} ${workspace.courseDetails.year} `;
      const qParams: any = {};

      if (queryParams.assignmentId) {
        qParams.assignmentId = queryParams.assignmentId;
      }

      setBreadcrumbs([
        { id: nanoid(), label: "Home", routeLocation: { name: "Courses" } },
        {
          id: nanoid(),
          label: fullCourseName,
          routeLocation: {
            name: "Course",
            params: { courseId: workspace.courseId },
          },
        },
        {
          id: nanoid(),
          label: workspace.name,
          routeLocation: {
            name: "WorkspaceViewer",
            params: { workspaceId: workspace.id },
            query: qParams,
          },
        },
      ]);
      break;
    default:
      setBreadcrumbs([]);
  }
}

export default router;
