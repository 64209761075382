import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import config from "@/config";

const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
};
const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export const db = app.firestore();
export const fn = app.functions("us-west2");
export const storage = app.storage();

if (config.useEmulators) {
  auth.useEmulator("http://localhost:9099");
  db.useEmulator("localhost", 8079);
  fn.useEmulator("localhost", 5001);
  storage.useEmulator("localhost", 9199);
}

export const timestamp = (): firebase.firestore.Timestamp => {
  return firebase.firestore.Timestamp.now();
};
