
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import useAuth from "@/composables/useAuth";

import Icon from "@/components/Icon.vue";
import BreadcrumbBar from "@/components/BreadcrumbBar.vue";
import IdleSignOut from "@/components/IdleSignOut.vue";
import useFollowMode from "./composables/useFollowMode";

export default defineComponent({
  components: { IdleSignOut, Icon, BreadcrumbBar },

  setup() {
    const { user, userSignedIn } = useAuth();
    const router = useRouter();
    const { amFollowSessionFollower } = useFollowMode();

    let userMenu = ref(false);
    const helpMenu = ref(false);
    let userInitials = ref("");

    const getUserInitials = computed(() => {
      if (userSignedIn.value && user.value?.displayName != null) {
        let names = user.value.displayName.split(" ");
        let initials = "";
        names.forEach((name: string) => {
          initials = initials + name[0];
        });

        return initials.substring(0, 3);
      } else {
        return "";
      }
    });

    function toggleUserMenu() {
      if (userMenu.value) {
        userMenu.value = false;
      } else {
        helpMenu.value = false;
        userMenu.value = true;
      }
    }

    function toggleHelpMenu() {
      if (helpMenu.value) {
        helpMenu.value = false;
      } else {
        userMenu.value = false;
        helpMenu.value = true;
      }
    }

    function signOut() {
      userMenu.value = false;
      helpMenu.value = false;
      router.push({ name: "SignOut" });
    }

    function isCodeListing(domElt: any) {
      var curElt = domElt;
      while (curElt) {
        if (
          curElt.className &&
          (curElt.className.indexOf("code-listing-line") !== -1 ||
            curElt.className.indexOf("code-listing") !== -1)
        ) {
          return true;
        }
        curElt = curElt.parentNode;
      }
      return false;
    }

    function copyHandler(e: any) {
      // get current selection
      let selection = window.getSelection();

      // override behavior when one wants to copy lines of code
      if (isCodeListing(selection?.anchorNode)) {
        let selectionText = "";
        // workaround an issue with Microsoft Edge as copied line breaks
        // are removed otherwise from the selection string

        selectionText = selection?.toString() ?? "";

        let lines = selectionText?.split("\n");

        let temp = [];
        for (let i in lines) {
          const pos = lines[i].search("​");
          if (pos >= 0 || lines[i] != "") {
            lines[i] = lines[i].substring(pos + 1, lines[i].length);
            temp.push(lines[i]);
          }
        }
        const cleanText = temp.join("\n");
        e.clipboardData?.setData("text/plain", cleanText);
        e.preventDefault();
      }
    }
    document.addEventListener("copy", copyHandler);

    return {
      userSignedIn,
      userMenu,
      userInitials,
      getUserInitials,
      toggleUserMenu,
      signOut,
      user,
      amFollowSessionFollower,
      helpMenu,
      toggleHelpMenu,
    };
  },
});
