import { Breadcrumb } from "@/types";
import { ref } from "vue";

const breadcrumbs = ref<Breadcrumb[]>([]);

export default function useBreadcrumb() {
  function setBreadcrumbs(breadcrumbList: Breadcrumb[]) {
    breadcrumbs.value = breadcrumbList;
  }

  return {
    breadcrumbs,
    setBreadcrumbs,
  };
}
