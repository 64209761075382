
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    name: {
      required: true,
      type: String,
    },
    class: {
      required: false,
      type: String,
    },
    color: {
      required: false,
      type: String,
    },
    height: {
      required: false,
      type: String,
    },
    width: {
      required: false,
      type: String,
    },
  },
  setup(props) {
    const defaultColor = ref<string>("rgb(75, 85, 99)");
    const defaultHeight = ref<string>("16");
    const defaultWidth = ref<string>("16");
    const defaultClass = ref<string>("flex-shrink-0");

    if (props.class) {
      defaultClass.value = defaultClass.value + " " + props.class;
    }

    return { defaultColor, defaultHeight, defaultWidth, defaultClass };
  },
});
