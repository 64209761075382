<template>
  <div
    class="absolute top-18 left-0 right-0 bottom-0 bg-gray-100 flex flex-col items-center overflow-y-scroll"
    style="display: flex"
  >
    <div class="bg-white shadow-md py-12 px-4 my-8 w-screen md:w-2/3 md:px-20">
      <h1 class="text-2xl font-semibold">Privacy Policy</h1>
      <p class="mt-1 italic">Last updated: 17 August 2021</p>

      <div class="text-base text-gray-700 space-y-6 text-justify">
        <ol style="list-style-type: upper-roman">
          <li>
            INTRODUCTION

            <p>
              This Privacy Policy (the “Policy”) represents the policy of
              STEAMPlug LLC (the “Company”) regarding the collection, use, and
              management of personal data belonging to:
            </p>

            <p></p>

            <ul>
              <li>
                All visitors (“Site Visitors”) to the Company’s website located
                at
                <kbd
                  ><a id="tex2html1" href="https://courses.steamplug.com"
                    >https://courses.steamplug.com</a
                  ></kbd
                >
                (the “Website”);

                <p></p>
              </li>

              <li>
                Anyone who downloads or uses (“Platform Visitors”) the Company’s
                software platform (the “Platform”);

                <p></p>
              </li>

              <li>
                Anyone who enrolls for the Services of the Company
                (“Subscribers”).
              </li>
            </ul>

            <p>
              Site Visitors, Platform Visitors, and Clients are referred to
              collectively as “Users”. The Website and the Platform together
              with the Services (as defined below) and all content contained
              therein are referred to collectively as the “Resources” as further
              described in the User Agreement.
            </p>

            <p>
              In order to ensure the free, informed, and unambiguous consent of
              our Users to the collection and use of personal data, the Company
              requires all Users to read this Policy and accept the terms and
              conditions contained herein, together with consent to release
              personal data subject to such terms and conditions.
            </p>

            <p>
              Capitalized terms used but not otherwise defined shall have the
              respective meanings ascribed thereto in the Company’s Terms of
              Service/End User License Agreement located at
              <kbd
                ><a id="tex2html2" href="https://courses.steamplug.com/terms"
                  >https://courses.steamplug.com/terms</a
                ></kbd
              >
              (the “User Agreement”).
            </p>

            <p></p>
          </li>

          <li>
            OVERVIEW

            <p>This Policy addresses the following topics:</p>

            <p></p>

            <ul>
              <li>What data do we collect?</li>
              <li>How do we collect your data?</li>
              <li>How will we use your data?</li>
              <li>How do we store your data?</li>
              <li>Data retention and deletion</li>
              <li>Data usage and processing</li>
              <li>Data sharing and disclosure</li>
              <li>Marketing</li>
              <li>What are your data protection rights?</li>
              <li>What are cookies?</li>
              <li>How do we use cookies?</li>
              <li>What types of cookies do we use?</li>
              <li>How to manage your cookies</li>
              <li>Privacy policies of other websites</li>
              <li>Changes to our privacy policy</li>
              <li>How to contact us</li>
              <li>How to contact the appropriate authorities</li>
            </ul>

            <p></p>
          </li>

          <li>
            WHAT DATA DO WE COLLECT?

            <p>The Company may collect the following data on all Users:</p>

            <p></p>

            <ul>
              <li>
                Personal identification information, such as name, email,
                address, and phone number
              </li>
              <li>
                Information on your browser, IP address, location, and device
              </li>
              <li>
                Information on your behavior on our website, including pages
                viewed, links clicked, keystrokes, and actions taken including
                copy/paste functions
              </li>
              <li>Demographic information</li>
              <li>
                Transaction information, including information related to the
                use of the Services, types of Services requested, dates and
                times of such requests, payment methods, and other similar
                information
              </li>
              <li>Location data, including User location searches</li>
              <li>
                Communications data, including messages, comments, or file
                transmissions
              </li>
              <li>
                About your internet connection, the equipment you use to access
                our Resources, and usage details
              </li>
            </ul>

            <p>
              <span class="textbf">Children Under the Age of 13</span>

              <br />
              <br />
              Our Resources are not intended for children under 13 years of age.
              No one under age 13 may provide any information to or on the
              Resources. We do not knowingly collect personal information from
              children under 13. If you are under 13, do not use or provide any
              information on this Resources or through any of its features,
              register on the Resources, make any purchases through the
              Resources, use any of the interactive or public comment features
              of this Resources, or provide any information about yourself to
              us, including your name, address, telephone number, email address,
              or any screen name or user name you may use. If we learn we have
              collected or received personal information from a child under 13
              without verification of parental consent, we will delete that
              information. If you believe we might have any information from or
              about a child under 13, please contact us at info@steamplug.com.
            </p>

            <p>
              California residents under 13 years of age may have additional
              rights regarding the collection and sale of their personal
              information. Please see Your California Privacy Rights (below) for
              more information.
            </p>

            <p></p>
          </li>

          <li>
            HOW DO WE COLLECT YOUR DATA?

            <p>
              Users directly provide data to the Company. The Company collects
              data and processes data when Users:
            </p>

            <p></p>

            <ul>
              <li>
                Register online or place an order for any of our products or
                Services
              </li>
              <li>
                Voluntarily complete a customer survey or provide feedback on
                any of our message boards or via email
              </li>
              <li>Use or view the Site via your browser’s cookies</li>
              <li>Download or use the Platform</li>
              <li>
                Submit content to the Site, like reviews, messages, or comments
              </li>
              <li>
                Enable access to their device’s location-tracking functionality,
                whether the Site or Platform is running in the foreground
                (Platform or Site open and on-screen) or background (Platform or
                Site open but not on-screen). Certain functionality may be
                affected while location data is turned off.
              </li>
            </ul>

            <p>
              Clients expressly and directly provide personal data, including
              payment information, to the Company via the Site when enrolling
              for the Services.
            </p>

            <p></p>
          </li>

          <li>
            HOW WILL WE USE YOUR DATA

            <p>The Company collects data from Users in order to:</p>

            <p></p>

            <ul>
              <li>
                To enhance the safety and security of our Users and Services
              </li>
              <li>For customer support</li>
              <li>For research and development</li>
              <li>To enable communications between users</li>
              <li>
                To send marketing and non-marketing communications to users
              </li>
              <li>In connection with legal proceedings</li>
            </ul>

            <p>
              The Company does not sell or share user personal data with third
              parties for their direct marketing, except with users’ consent.
            </p>

            <p>
              The Company provides software Services as defined in the User
              Agreement. The Company may collect data from its Clients in order
              to perform such services (the “Services”). You acknowledge,
              accept, and agree that without the data collected as set forth
              above, the Company will not be able to provide the Services and
              most functionality of the Platform.
            </p>

            <p>
              Clients consent to the release of this information and data to the
              Company for the purpose of the performance of the Company’s
              Services for the Client’s benefit. This release of information and
              the relationship between the Company and its Clients is subject to
              the User Agreement.
            </p>

            <p>
              Your Instructor will have access to all of your User Content (as
              defined in the User Agreement), including without limitation every
              keystroke when entering text, responses, code, assignment, or any
              other input into the Platform. You acknowledge and agree that this
              access is essential to the proper administration of the classroom,
              the curriculum, and school policy and that this access is fully
              and completely covered by the license granted by you to the
              Company and each Instructor User in and to your User Content as
              set forth in the User Agreement.
            </p>

            <p></p>
          </li>

          <li>
            HOW DO WE STORE YOUR DATA?

            <p>
              The Company securely stores Client data on servers leased from a
              reputable third party hosting service located in the United
              States. Data is retained in accordance with the Company’s User
              Agreement.
            </p>

            <p></p>
          </li>

          <li>
            DATA RETENTION AND DELETION

            <p>
              The Company retains user profile, transaction, and other
              information for as long as a user maintains their account.
            </p>

            <p>
              Users may request deletion of their account at any time. Following
              such requests, the Company deletes the data that it is not
              required to retain for purposes of regulatory, tax, insurance,
              litigation, or other legal requirements. For example, the Company
              retains location, device, and usage data for these purposes for a
              minimum of 7 years; while it retains such data, it may also use it
              for purposes of safety, security, fraud prevention and detection,
              and research and development. In certain circumstances, the
              Company may be unable to delete a user’s account, such as if
              there’s an outstanding credit on the account or an unresolved
              claim or dispute. Upon resolution of the issue preventing
              deletion, the Company will delete the account as described above.
            </p>

            <p>
              The Company may also retain certain information if necessary, for
              purposes of safety, security, and fraud prevention. For example,
              if the Company deactivates a user’s account because of unsafe
              behavior or security incidents, the Company may retain certain
              information about that account to prevent that user from opening a
              new account in the future.
            </p>

            <p></p>
          </li>

          <li>
            DATA USAGE AND PROCESSING

            <p>
              We collect and use personal data only where we have one or more
              lawful grounds for doing so. Such grounds may vary depending on
              where our Users are located, but generally include processing
              personal data:
            </p>

            <p></p>

            <ol>
              <li>
                To provide requested Services and features

                <p>
                  In order to provide our Services, we must collect and use
                  certain personal data. This includes:
                </p>

                <p></p>

                <ul>
                  <li>
                    User profile data, which we use to establish and maintain
                    User accounts; verify User identity; communicate with Users
                    about their orders and accounts; and enable Users to make or
                    receive payments (as and if applicable)
                  </li>
                  <li>
                    Usage data, which is necessary to maintain, optimize, and
                    enhance the Company’s Services
                  </li>
                  <li>Transaction information</li>
                  <li>Information relating to customer support</li>
                </ul>

                <p></p>
              </li>

              <li>
                For purposes of the legitimate interests of the Company or other
                parties

                <p>
                  This includes using personal data to maintain and enhance our
                  Users’ safety and security. For example, we use personal data
                  to prevent use of our Services by Users who have engaged in
                  inappropriate or dangerous behavior, such as by retaining data
                  of banned Users to prevent their use of the Platform. This
                  also includes purposes such as combating fraud; improving our
                  Services, direct marketing, research, and development; and
                  enforcing the Company’s User Agreement. In addition, it
                  includes using personal data to the extent necessary for the
                  interests of other people or the general public, such as in
                  connection with legal or insurance claims, and to protect the
                  rights and safety of others.
                </p>

                <p></p>
              </li>

              <li>
                To fulfill the Company’s legal obligations

                <p>
                  We collect and use personal data to comply with applicable
                  laws. The Company may also share data with law enforcement
                  regarding criminal acts or threats to public safety, or
                  requests by third parties pursuant to legal processes.
                </p>

                <p></p>
              </li>

              <li>
                With consent

                <p>
                  The Company may collect and use personal data based on the
                  User’s consent. For example, we may collect personal data
                  through voluntary surveys. Responses to such surveys are
                  collected on the basis of consent and will be deleted once no
                  longer necessary for the purposes collected.
                </p>

                <p>
                  A User who has provided consent to a collection or use of
                  their personal data can revoke it at any time. However, the
                  User will not be able to use any Service or feature that
                  requires collection or use of that personal data.
                </p>
              </li>
            </ol>

            <p></p>
          </li>

          <li>
            DATA SHARING AND DISCLOSURE

            <p>
              Some of the Company’s products, Services, and features require
              that we share data with other Users or at a User’s request. We may
              also share data with our affiliates, subsidiaries, and partners,
              for legal reasons or in connection with claims or disputes.
            </p>

            <p>The Company may share the data we collect:</p>

            <p></p>

            <ol>
              <li>
                With Other Users

                <p>
                  This includes sharing User’s personally identifying
                  information shared on the Platform, including to such User’s
                  Profile on the Platform
                </p>

                <p></p>
              </li>

              <li>
                At the User’s request

                <p>This includes sharing data with:</p>

                <p></p>

                <ul>
                  <li>Other people at the User’s request.</li>
                  <li>
                    Company business partners. For example, if a User requests a
                    service through a partnership or promotional offering made
                    by a third party, the Company may share certain data with
                    those third parties. This may include, for example, other
                    services, platforms, apps or websites that integrate with
                    our APIs.
                  </li>
                </ul>

                <p></p>
              </li>

              <li>
                With the general public

                <p>
                  Questions or comments from Users submitted through public
                  forums such as the Company blogs and the Company social media
                  pages may be viewable by the public, including any personal
                  data included in the questions or comments submitted by a
                  User.
                </p>

                <p></p>
              </li>

              <li>
                With the User account owner

                <p>
                  If a User requests transportation or places an order using an
                  account owned by another party, we may share their order or
                  trip information, including real-time location data, with the
                  owner of that account.
                </p>

                <p></p>
              </li>

              <li>
                With the Company subsidiaries and affiliates

                <p>
                  We share data with our subsidiaries and affiliates to help us
                  provide our services or conduct data processing on our behalf.
                  For example, the Company processes and stores data in the
                  United States on behalf of its international subsidiaries and
                  affiliates.
                </p>

                <p></p>
              </li>

              <li>
                With the Company service providers and business partners

                <p>
                  The Company provides data to vendors, consultants, marketing
                  partners, research firms, and other service providers or
                  business partners. These include:
                </p>

                <p></p>

                <ul>
                  <li>Payment processors and facilitators</li>
                  <li>
                    Background check and identity verification providers (as
                    applicable)
                  </li>
                  <li>Cloud storage providers</li>
                  <li>
                    Marketing partners and marketing platform providers,
                    including social media advertising services
                  </li>
                  <li>Data analytics providers</li>
                  <li>
                    Research partners, including those performing surveys or
                    research projects in partnership with the Company or on the
                    Company’s behalf
                  </li>
                  <li>
                    Vendors that assist the Company to enhance the safety and
                    security of its apps
                  </li>
                  <li>
                    Consultants, lawyers, accountants, and other professional
                    service providers
                  </li>
                  <li>Insurance and financing partners</li>
                  <li>
                    Vehicle solution vendors or third-party vehicle suppliers
                  </li>
                </ul>

                <p></p>
              </li>

              <li>
                For legal reasons or in the event of a dispute

                <p>
                  The Company may share Users’ personal data if we believe it’s
                  required by applicable law, regulation, operating license or
                  agreement, legal process or governmental request, or where the
                  disclosure is otherwise appropriate due to safety or similar
                  concerns. This includes sharing personal data with law
                  enforcement officials, public health officials, other
                  government authorities, or other third parties as necessary to
                  enforce our User Agreement or other policies; to protect the
                  Company’s rights or property or the rights, safety, or
                  property of others; or in the event of a claim or dispute
                  relating to the use of our services. If you use another
                  person’s credit card, we may be required by law to share your
                  personal data, including trip or order information, with the
                  owner of that credit card.
                </p>

                <p>
                  This also includes sharing personal data with others in
                  connection with, or during negotiations of, any merger, sale
                  of company assets, consolidation or restructuring, financing,
                  or acquisition of all or a portion of our business by or into
                  another company.
                </p>

                <p></p>
              </li>

              <li>
                With consent

                <p>
                  The Company may share a User’s personal data other than as
                  described in this notice if we notify the User and they
                  consent to the sharing.
                </p>
              </li>
            </ol>

            <p></p>
          </li>

          <li>
            MARKETING

            <p>
              The Company may send its Users information about products and
              services of the Company, always with the consent of the User.
              Clients consent to marketing and promotional communication when
              they enroll for the Services pursuant to the User Agreement.
            </p>

            <p>
              If you have agreed to receive such marketing materials, you may
              always opt out at a later date. You have the right at any time to
              stop the Company from contacting you for marketing purposes.
            </p>

            <p></p>
          </li>

          <li>
            WHAT ARE YOUR DATA PROTECTION RIGHTS?

            <p>
              The Company is committed to making you fully aware of all of your
              data protection rights. Every User is entitled to the following:
            </p>

            <p></p>
            <dl>
              <dt></dt>
              <dd>
                <span class="textbf">The right to access</span> - You have the
                right to request from the Company copies of your personal data.
                We may charge you a small fee for this service.

                <p></p>
              </dd>

              <dt></dt>
              <dd>
                <span class="textbf">The right to rectification</span> - You
                have the right to request that the Company correct any
                information you believe is inaccurate. You also have the right
                to request the Company to complete information you believe is
                incomplete.

                <p>
                  For the avoidance of doubt, the right to rectification refers
                  only to factual data and not User Content submitted by you in
                  response to classroom assignment or exams.
                </p>

                <p></p>
              </dd>

              <dt></dt>
              <dd>
                <span class="textbf">The right to erasure</span> - You have the
                right to request that the Company erase your personal data,
                under certain conditions.

                <p></p>
              </dd>

              <dt></dt>
              <dd>
                <span class="textbf">The right to restrict processing</span> -
                You have the right to request that the Company restrict the
                processing of your personal data, under certain conditions.

                <p></p>
              </dd>

              <dt></dt>
              <dd>
                <span class="textbf">The right to object to processing</span> -
                You have the right to object to the Company’s processing of your
                personal data, under certain conditions.

                <p></p>
              </dd>

              <dt></dt>
              <dd>
                <span class="textbf">The right to data portability</span> - You
                have the right to request that the Company transfer the data
                collected to another organization, or directly to you, under
                certain conditions.
              </dd>
            </dl>

            <p>
              Clients who voluntarily provide their personal data in order for
              the Company to provide Services do so pursuant to the Company’s
              User Agreement. The retention, return, transfer, and destruction
              of personal data provided for such purposes is subject to the
              terms of such User Agreement.
            </p>

            <p>
              If you make a request, the Company has one month to respond to
              you. If you would like to exercise any of these rights, please
              contact us at: info@steamplug.com.

              <br />
              <br />
              <span class="textbf"
                ><span id="txt43">Your California Privacy Rights</span></span
              >

              <br />
              <br />
              <span class="texttt"
                ><a
                  href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1798.83."
                  target="_blank"
                  >California Civil Code Section 1798.83</a
                ></span
              >
              permits residents of the State of California to request from
              certain businesses with whom the California resident has an
              established business relationship a list of all third parties to
              which the business, during the immediately preceding calendar
              year, has disclosed certain personally identifiable information
              for direct marketing purposes. We are required to respond to a
              customer request only once during any calendar year. To make such
              a request you should contact admin@steamplug.com. In your request,
              please attest to the fact that you are a California resident and
              provide a current California address for our response. Please be
              aware that not all information sharing is covered by the
              California Privacy Rights requirements and only information
              sharing that is covered will be included in our response.
            </p>

            <p></p>
          </li>

          <li>
            WHAT ARE COOKIES?

            <p>
              Cookies are text files placed on your computer to collect standard
              Internet log information and visitor behavior information. When
              you visit the Site, we may collect information from you
              automatically through cookies or similar technology.
            </p>

            <p>
              For further information, visit
              <kbd
                ><a id="tex2html3" href="allaboutcookies.org"
                  >allaboutcookies.org</a
                ></kbd
              >.
            </p>

            <p></p>
          </li>

          <li>
            HOW DO WE USE COOKIES?

            <p>
              The Company uses cookies and similar technologies for purposes
              such as:
            </p>

            <p></p>

            <ul>
              <li>Authenticating Users</li>
              <li>Remembering User preferences and settings</li>
              <li>Determining the popularity of content</li>
              <li>
                Delivering and measuring the effectiveness of advertising
                campaigns
              </li>
              <li>
                Analyzing site traffic and trends, and generally understanding
                the online behaviors and interests of people who interact with
                our services
              </li>
            </ul>

            <p>
              We may also allow others to provide audience measurement and
              analytics services for us, to serve advertisements on our behalf
              across the Internet, and to track and report on the performance of
              those.
            </p>

            <p></p>
          </li>

          <li>
            HOW TO MANAGE YOUR COOKIES

            <p>
              You can set your browser not to accept cookies, and you can remove
              cookies from your browser. However, in a few cases, some of the
              Site features may not function as a result.
            </p>

            <p></p>
          </li>

          <li>
            PRIVACY POLICIES OF OTHER WEBSITES

            <p>
              The Site contains links to other websites. This Policy applies
              only to the Site. If you navigate to any other website from a link
              on the Site, you should review the privacy policy of such website.
            </p>

            <p></p>
          </li>

          <li>
            CHANGES TO OUR PRIVACY POLICY

            <p>
              It is our policy to post any changes we make to our Policy on this
              page. If we make material changes to how we treat our users'
              personal information, through a notice on the Website home page or
              in the Platform. The date the Policy was last revised is
              identified at the top of the page. You are responsible for
              ensuring we have an up-to-date active and deliverable email
              address for you, and for periodically visiting our Resources and
              this Policy to check for any changes.
            </p>

            <p></p>
          </li>

          <li>
            HOW TO CONTACT US

            <p>
              If you have any questions about this Policy, the data we hold from
              you, or you would like to exercise one of your data protection
              rights, please do not hesitate to contact us:
            </p>

            <p>Email: info@steamplug.com</p>

            <p></p>
          </li>

          <li>
            HOW TO CONTACT THE APPROPRIATE AUTHORITIES

            <p>
              Should you wish to report a complaint or if you feel that the
              Company has not addressed your concern in a satisfactory manner,
              you may contact your local Data Protection Authority.
            </p>

            <p>
              Click
              <a
                href="https://edpb.europa.eu/about-edpb/board/members_en"
                target="_blank"
                >here</a
              >
              for a list of jurisdictions, together with their respective Data
              Protection Authority and related contact information.
            </p>

            <p></p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useBreadcrumb from "@/composables/useBreadcrumb";
import { defineComponent } from "vue";
import { nanoid } from "nanoid";

export default defineComponent({
  setup() {
    const { setBreadcrumbs } = useBreadcrumb();

    setBreadcrumbs([
      { id: nanoid(), label: "Home", routeLocation: { name: "Courses" } },
      {
        id: nanoid(),
        label: "Privacy Policy",
        routeLocation: {
          name: "Privacy",
        },
      },
    ]);
  },
});
</script>

<style scoped>
ol {
  list-style-type: upper-roman;
}

ol ol {
  list-style-type: decimal;
}

ol ol ol {
  list-style-type: lower-roman;
}

ol ol ol ol {
  list-style-type: upper-alpha;
}

ul {
  list-style-type: disc;
}

html,
address,
blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
frame,
frameset,
h1,
h2,
h3,
h4,
h5,
h6,
noframes,
ol,
p,
ul,
center,
dir,
hr,
menu,
pre {
  display: block;
  unicode-bidi: embed;
}
li {
  display: list-item;
}
head {
  display: none;
}
table {
  display: table;
}
tr {
  display: table-row;
}
thead {
  display: table-header-group;
}
tbody {
  display: table-row-group;
}
tfoot {
  display: table-footer-group;
}
col {
  display: table-column;
}
colgroup {
  display: table-column-group;
}
td,
th {
  display: table-cell;
}
caption {
  display: table-caption;
}
th {
  font-weight: bolder;
  text-align: center;
}
caption {
  text-align: center;
}
body {
  margin: 8px;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}
h3 {
  font-size: 1.17em;
  margin: 0.83em 0;
}
h4,
p,
blockquote,
ul,
fieldset,
form,
ol,
dl,
dir,
menu {
  margin: 1.12em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.5em 0;
}
h6 {
  font-size: 0.75em;
  margin: 1.67em 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: bolder;
}
blockquote {
  margin-left: 40px;
  margin-right: 40px;
}
i,
cite,
em,
var,
address {
  font-style: italic;
}
pre,
tt,
code,
kbd,
samp {
  font-family: monospace;
}
pre {
  white-space: pre;
}
button,
textarea,
input,
select {
  display: inline-block;
}
big {
  font-size: 1.17em;
}
small,
sub,
sup {
  font-size: 0.83em;
}
sub {
  vertical-align: sub;
}
sup {
  vertical-align: super;
}
table {
  border-spacing: 2px;
}
thead,
tbody,
tfoot {
  vertical-align: middle;
}
td,
th,
tr {
  vertical-align: inherit;
}
s,
strike,
del {
  text-decoration: line-through;
}
hr {
  border: 1px inset;
}
ol,
ul,
dir,
menu,
dd {
  margin-left: 40px;
}
ol {
  list-style-type: decimal;
}
ol ul,
ul ol,
ul ul,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}
u,
ins {
  text-decoration: underline;
}
br:before {
  content: "\A";
  white-space: pre-line;
}
center {
  text-align: center;
}
:link,
:visited {
  text-decoration: underline;
}
:focus {
  outline: thin dotted invert;
}

/* Begin bidirectionality settings (do not change) */
BDO[DIR="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}
BDO[DIR="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

*[DIR="ltr"] {
  direction: ltr;
  unicode-bidi: embed;
}
*[DIR="rtl"] {
  direction: rtl;
  unicode-bidi: embed;
}

@media print {
  h1 {
    page-break-before: always;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }
}

li {
  margin-top: 1rem;
}

span.textbf {
  font-weight: bold;
}
span.textit {
  font-style: italic;
}
#txt100 {
  text-decoration: underline;
}
#txt101 {
  text-decoration: underline;
}
#txt103 {
  text-decoration: underline;
}
#txt112 {
  text-decoration: underline;
}
#txt113 {
  text-decoration: underline;
}
#txt114 {
  text-decoration: underline;
}
#txt115 {
  text-decoration: underline;
}
#txt116 {
  text-decoration: underline;
}
#txt117 {
  text-decoration: underline;
}
#txt118 {
  text-decoration: underline;
}
#txt119 {
  text-decoration: underline;
}
#txt120 {
  text-decoration: underline;
}
#txt20 {
  text-decoration: underline;
}
#txt21 {
  text-decoration: underline;
}
#txt26 {
  text-decoration: underline;
}
#txt27 {
  text-decoration: underline;
}
#txt28 {
  text-decoration: underline;
}
#txt32 {
  text-decoration: underline;
}
#txt33 {
  text-decoration: underline;
}
#txt39 {
  text-decoration: underline;
}
#txt43 {
  text-decoration: underline;
}
#txt44 {
  text-decoration: underline;
}
#txt45 {
  text-decoration: underline;
}
#txt49 {
  text-decoration: underline;
}
#txt53 {
  text-decoration: underline;
}
#txt55 {
  text-decoration: underline;
}
#txt56 {
  text-decoration: underline;
}
#txt57 {
  text-decoration: underline;
}
#txt66 {
  text-decoration: underline;
}
#txt69 {
  text-decoration: underline;
}
#txt73 {
  text-decoration: underline;
}
#txt78 {
  text-decoration: underline;
}
#txt79 {
  text-decoration: underline;
}
#txt80 {
  text-decoration: underline;
}
#txt81 {
  text-decoration: underline;
}
#txt85 {
  text-decoration: underline;
}
#txt86 {
  text-decoration: underline;
}
#txt94 {
  text-decoration: underline;
}
#txt98 {
  text-decoration: underline;
}
#txt99 {
  text-decoration: underline;
}
</style>
