
import useBreadcrumb from "@/composables/useBreadcrumb";
import { defineComponent } from "vue";
import { nanoid } from "nanoid";

export default defineComponent({
  setup() {
    const { setBreadcrumbs } = useBreadcrumb();

    setBreadcrumbs([
      { id: nanoid(), label: "Home", routeLocation: { name: "Courses" } },
      {
        id: nanoid(),
        label: "Terms of Use",
        routeLocation: {
          name: "Terms",
        },
      },
    ]);
  },
});
